import React from "react";

import("../styles/nosotros.css");

const Nosotros = () => {
  return (
    <main>
      <section className="nosotros">
        <h1>LINEA DE TIEMPO</h1>
        <article className="time-inpar">
          <div className="img-date">
            <div className="date date2009">
              <h2>2009</h2>
            </div>
            <div className="img">
              <img
                src="https://images.pexels.com/photos/15286/pexels-photo.jpg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt="Foto"
              />
            </div>
          </div>
          <div className="description-time">
            <h3>COLECTIVO LLANO FELIZ</h3>
            <p>
              Primer esfuerzo colectivo con enfasis ambiental, que percibe y
              busca soluciones a los impactos ambientales en el ambito local. En
              esta etapa se gestaron obras de alto impacto social y con
              strategias efectivas de activismo. Que luego se implementaron con
              exito.
            </p>
          </div>
        </article>
        <hr />
        <article className="time-par">
          <div className="description-time">
            <h3>ORGANIZACION COLOMBIA LIMPIA</h3>
            <p>
              Como colectivo nos consolidamos para abarcar un rango mas amplio
              de acciones desde lo nacional. Encontramos la manera de
              organizarnos y comunicarnos para sumar esfuerzos en todo el pais.
            </p>
          </div>
          <div className="img-date">
            <div className="img">
              <img
                src="https://images.pexels.com/photos/1011302/pexels-photo-1011302.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt="Foto"
              />
            </div>
            <div className="date date2012">
              <h2>2012</h2>
            </div>
          </div>
        </article>
        <hr />

        <article className="time-inpar">
          <div className="img-date">
            <div className="date date2014">
              <h2>2014</h2>
            </div>
            <div className="img">
              <img
                src="https://images.pexels.com/photos/3464632/pexels-photo-3464632.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt="Foto"
              />
            </div>
          </div>
          <div className="description-time">
            <h3>ESTRATEGIA CASANARE SIN LLANTAS</h3>
            <p>
              Gracias al esfuerzo de nuestros voluntarios y de la comunidad se
              creo la primero eco-graderia que en el municipio de Yopal Casanare
              para aportar a la recreacion y el deporte de todos. Esta obra fue
              creada con mas de 5.000 llantas recuparadas de los exosistemas
              locales y se proyecta que puede durar mas de 50 años con bajo
              mantenimiento.
            </p>
          </div>
        </article>
        <hr />

        <article className="time-par">
          <div className="description-time">
            <h3>ORGANIZACION PLANETA LIMPIO (O.P.L)</h3>
            <p>
              Luego de sumar mas de 2.000 miembros por toda Colombia en donde
              asistimos personalmente a cada departamento y de contar desde
              entonces con miembros de otros paises de Latinoamerica, decidimos
              proyectarnos como organizacion internacional.
            </p>
          </div>
          <div className="img-date">
            <div className="date date2016">
              <h2>2016</h2>
            </div>
            <div className="img">
              <img
                src="https://images.pexels.com/photos/4951280/pexels-photo-4951280.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt="Foto"
              />
            </div>
          </div>
        </article>
        <hr />

        <article className="time-inpar">
          <div className="img-date">
            <div className="date date2020">
              <h2>2020</h2>
            </div>
            <div className="img">
              <img
                src="https://images.pexels.com/photos/3265460/pexels-photo-3265460.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt="Foto"
              />
            </div>
          </div>
          <div className="description-time">
            <h3>EJERCITO AMBIENTAL DEL PLANETA</h3>
            <p>
              E.A.P. Recorrido Por Sudamerica, luego de la expansion de la fase
              "O.P.L". Nos enfocamos en llevar nuestra estrategia de actividades
              ambientales, rangos y la dinamica social entorno a este enfoque
              por todos los paises de sudamerica. Desarrollamos y pusimos a
              prueba en esta etapa los metodos mas eficaces para fomentar el
              amor por la naturaleza.
            </p>
          </div>
        </article>
        <hr />

        <article className="time-par">
          <div className="description-time">
            <h3>VOLUNTEERS</h3>
            <p>
              Luego de mas de una decada, creamos nuestra plataforma, para
              llevar el seguimiento de 27 actividades Socio-Ambientales
              perfeccionadas a lo largo de los años, para tener el mayor alcance
              positivo posible, con el apoyo de empresas, entidades y miles de
              voluntarios certificados.
            </p>
          </div>
          <div className="img-date">
            <div className="date date2022">
              <h2>2022</h2>
            </div>
            <div className="img">
              <img
                src="https://images.pexels.com/photos/1131406/pexels-photo-1131406.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt="Foto"
              />
            </div>
          </div>
        </article>
      </section>
    </main>
  );
};

export default Nosotros;
