import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmarkCircle } from "@fortawesome/free-regular-svg-icons";

import { useUser } from "../../context/providers/userContext";
import "../../styles/signup.css";
import { Spinner } from "../../components/ui/Spinner";
import Conditions from "../../components/ui/Conditions";

const SendEmailPassword = () => {
  const [user, setUser] = useState({
    email: "",
  });

  const { isLoading, emailResetPassword } = useUser();

  const [viewConditionsEnable, setViewConditionsEnable] = useState(false);

  const navigate = useNavigate();

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSendPassword = async (e) => {
    e.preventDefault();
    try {
      await emailResetPassword(user);
      toast.success(
        "Te hemos enviado un mensaje!, Revisa tu correo electronico",
        {
          position: "bottom-right",
        }
      );
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  const viewConditions = async (e) => {
    e.preventDefault();
    try {
      if (!viewConditionsEnable) {
        setViewConditionsEnable(true);
        console.log("true");
      } else {
        setViewConditionsEnable(false);
        console.log("false");
      }
    } catch (error) {}
  };

  const closeConditions = (e) => {
    e.preventDefault();
    try {
      setViewConditionsEnable(false);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <main className="main">
      {isLoading ? (
        Spinner
      ) : (
        <section className="section-signup">
          <h2 className="title-send-email">Escribe tu correo electronico </h2>
          <div className="form">
            <Formik
              initialValues={user}
              validationSchema={Yup.object({
                email: Yup.string()
                  .required("Ingrese su correo.")
                  .email("Debe ser un email valido.")
                  .matches(/^[^@]+@[^@]+\..+$/, "Debe ser un email valido."),
              })}
              onSubmit={handleSendPassword}
              enableReinitialize={true}
              className="form"
            >
              <Form onSubmit={handleSendPassword}>
                <ErrorMessage
                  className="error-message"
                  component="p"
                  name="email"
                />
                <div className="input-label-signup">
                  <div className="img-icon">
                    <img
                      className="icono"
                      src="https://i.postimg.cc/fL6962YV/correo.png"
                      alt=""
                    />
                    <img src="" alt="" />
                  </div>
                  <Field
                    className="input-signup"
                    name="email"
                    type="text"
                    required
                    autoComplete="username"
                    onChange={handleChange}
                  />
                  <label className="label-signup" htmlFor="email">
                    Email
                  </label>
                </div>

                <button
                  className="button-enter-signup"
                  type="submit"
                  disabled={!user.email || isLoading}
                >
                  {isLoading ? "Cargando..." : "RESTABLECER CONTRASEÑA"}
                </button>
                <Link
                  onClick={(e) => {
                    viewConditions(e);
                  }}
                  className="terminosycondiciones"
                >
                  Terminos & Condiciones
                </Link>
              </Form>
            </Formik>
          </div>
        </section>
      )}
      <section className="section-condition">
        {viewConditionsEnable && (
          <div>
            <Conditions />
            <div
              className="button-close-conditions"
              onClick={(e) => {
                closeConditions(e);
              }}
            >
              <FontAwesomeIcon icon={faXmarkCircle} />
            </div>
          </div>
        )}
      </section>
    </main>
  );
};

export default SendEmailPassword;
