export const messagesActions = {
  LOAD_MESSAGES: "LOAD_MESSAGES",
  LOAD_MESSAGES_SUCCESS: "LOAD_MESSAGES_SUCCESS",
  LOAD_MESSAGES_ERROR: "LOAD_MESSAGES_ERROR",

  GET_MESSAGE: "GET_MESSAGE",
  GET_MESSAGE_SUCCESS: "GET_MESSAGE_SUCCESS",
  GET_MESSAGE_ERROR: "GET_MESSAGE_ERROR",

  SAVE_MESSAGE: "SAVE_MESSAGE",
  SAVE_MESSAGE_SUCCESS: "SAVE_MESSAGE_SUCCESS",
  SAVE_MESSAGE_ERROR: "SAVE_MESSAGE_ERROR",

  UPDATE_MESSAGE: "UPDATE_MESSAGE",
  UPDATE_MESSAGE_SUCCESS: "UPDATE_MESSAGE_SUCCESS",
  UPDATE_MESSAGE_ERROR: "UPDATE_MESSAGE_ERROR",

  REMOVE_MESSAGE: "REMOVE_MESSAGE",
  REMOVE_MESSAGE_SUCCESS: "REMOVE_MESSAGE_SUCCESS",
  REMOVE_MESSAGE_ERROR: "REMOVE_MESSAGE_ERROR",

  LOAD_MESSAGES_USER: "LOAD_MESSAGES_USER",
  LOAD_MESSAGES_USER_SUCCESS: "LOAD_MESSAGES_USER_SUCCESS",
  LOAD_MESSAGES_USER_ERROR: "LOAD_MESSAGES_USER_ERROR",

  VIEW_CHAT: "VIEW_CHAT",
  VIEW_CHAT_SUCCESS: "VIEW_CHAT_SUCCESS",
  VIEW_CHAT_ERROR: "VIEW_CHAT_ERROR",
};
