import { commentsActions } from "../actions/commentsActions";

export const initialState = {
  isLoadingComment: false,
  comments: [],
  commentsTarea: [],
  commentId: null,
  errorMessage: "",
  newComment: null,
  commentsPerfilAdmin: [],
};

export const commentsReducer = (state, actions) => {
  const { type, payload } = actions;

  switch (type) {
    case commentsActions.LOAD_COMMENTS:
      return {
        ...state,
        isLoadingComment: true,
      };
    case commentsActions.LOAD_COMMENTS_SUCCESS:
      return {
        ...state,
        comments: payload,
        isLoadingComment: false,
      };
    case commentsActions.LOAD_COMMENTS_ERROR:
      return {
        ...state,
        isLoadingComment: false,
        errorMessage: payload,
      };
    case commentsActions.SAVE_COMMENT:
      return {
        ...state,
        isLoadingComment: true,
      };
    case commentsActions.SAVE_COMMENT_SUCCESS:
      return {
        ...state,
        comments: [...state.comments, payload.comments],
        newComment: payload.newComment,
        isLoadingComment: false,
      };
    case commentsActions.SAVE_COMMENT_ERROR:
      return {
        ...state,
        isLoadingComment: false,
        errorMessage: payload,
      };
    case commentsActions.DELETE_COMMENT:
      return {
        ...state,
        isLoadingComment: true,
      };
    case commentsActions.DELETE_COMMENT_SUCCESS:
      return {
        ...state,
        comments: state.comments.filter((comment) => comment._id !== payload),
        isLoadingComment: false,
      };
    case commentsActions.DELETE_COMMENT_ERROR:
      return {
        ...state,
        isLoadingComment: false,
        errorMessage: payload,
      };
    case commentsActions.UPDATE_COMMENT:
      return {
        ...state,
        isLoadingComment: true,
      };
    case commentsActions.UPDATE_COMMENT_SUCCESS:
      return {
        ...state,
        commentId: payload,
        isLoadingComment: false,
      };
    case commentsActions.UPDATE_COMMENT_ERROR:
      return {
        ...state,
        isLoadingComment: false,
        errorMessage: payload,
      };
    case commentsActions.GET_COMMENT:
      return {
        ...state,
        isLoadingComment: true,
      };
    case commentsActions.GET_COMMENT_SUCCESS:
      return {
        ...state,
        commentId: payload,
        isLoadingComment: false,
      };
    case commentsActions.GET_COMMENT_ERROR:
      return {
        ...state,
        errorMessage: payload,
        isLoadingComment: false,
      };
    case commentsActions.GET_COMMENTS_TAREA:
      return {
        ...state,
        isLoadingComment: true,
      };
    case commentsActions.GET_COMMENTS_TAREA_SUCCESS:
      return {
        ...state,
        commentsTarea: payload,
        isLoadingComment: false,
      };
    case commentsActions.GET_COMMENTS_TAREA_ERROR:
      return {
        ...state,
        isLoadingComment: false,
        errorMessage: payload,
      };
    case commentsActions.CLEAN_COMMENT:
      return {
        ...state,
        isLoadingComment: true,
      };
    case commentsActions.CLEAN_COMMENT_SUCCESS:
      return {
        ...state,
        newComment: payload,
        isLoadingComment: false,
      };
    case commentsActions.CLEAN_COMMENT_ERROR:
      return {
        ...state,
        errorMessage: payload,
        isLoadingComment: false,
      };
    default:
      return state;
  }
};
