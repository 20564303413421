export const activitiesActions = {
  LOAD_ACTIVITIES: "LOAD_ACTIVITIES",
  LOAD_ACTIVITIES_SUCCESS: "LOAD_ACTIVITIES_SUCCESS",
  LOAD_ACTIVITIES_ERROR: "LOAD_ACTIVITIES_ERROR",

  SAVE_ACTIVITIES: "SAVE_ACTIVITIES",
  SAVE_ACTIVITIES_SUCCESS: "SAVE_ACTIVITIES_SUCCESS",
  SAVE_ACTIVITIES_ERROR: "SAVE_ACTIVITIES_ERROR",

  UPDATE_ACTIVITIES: "UPDATE_ACTIVITIES",
  UPDATE_ACTIVITIES_SUCCESS: "UPDATE_ACTIVITIES_SUCCESS",
  UPDATE_ACTIVITIES_ERROR: "UPDATE_ACTIVITIES_ERROR",

  DELETE_ACTIVITIES: "DELETE_ACTIVITIES",
  DELETE_ACTIVITIES_SUCCESS: "DELETE_ACTIVITIES_SUCCESS",
  DELETE_ACTIVITIES_ERROR: "DELETE_ACTIVITIES_ERROR",

  GET_ACTIVITIES: "GET_ACTIVITIES",
  GET_ACTIVITIES_SUCCESS: "GET_ACTIVITIES_SUCCESS",
  GET_ACTIVITIES_ERROR: "GET_ACTIVITIES_ERROR",
};
