import React from "react";

const OdsActivity6 = () => {
  return (
    <div className="ods-activities-container">
      <h2>ODS ACTIVIDAD 6:</h2>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645231/Volunteers/assets/ODS/3_tqqywb.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 3: Salud y Bienestar: (Meta 3.4) </span> <br />
          Apagar el celular por la noche puede mejorar la calidad del sueño,
          reduciendo el estrés y la fatiga, lo que contribuye al bienestar
          general. Desconectarse de la tecnología también puede promover una
          mejor salud mental al reducir la sobrecarga de información y la
          ansiedad.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645230/Volunteers/assets/ODS/7_gguoxk.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 7: Energía Asequible y No Contaminante: (Meta 7.3) </span>{" "}
          <br />
          Apagar el celular cuando no se usa reduce el consumo de energía,
          contribuyendo a la eficiencia energética. Si muchas personas adoptan
          este hábito, el ahorro energético acumulado puede ser significativo.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645227/Volunteers/assets/ODS/9_gwz6lw.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>
            ODS 9: Industria, Innovación e Infraestructura: (Meta 9.4){" "}
          </span>{" "}
          <br />
          Al reducir el uso de dispositivos electrónicos, se disminuye la
          presión sobre las infraestructuras de comunicación y se fomenta un uso
          más sostenible de las tecnologías de la información y la comunicación.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/12_totmyp.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>
            ODS 12: Producción y Consumo Responsables: (Meta 12.2, 12.5){" "}
          </span>{" "}
          <br />
          Apagar el celular regularmente reduce su desgaste y prolonga su vida
          útil, lo que puede disminuir la demanda de nuevos dispositivos y la
          generación de residuos electrónicos. Esto contribuye a un uso más
          sostenible y responsable de los recursos.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/13_tmtnkh.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 13: Acción por el Clima: (Meta 13.3) </span> <br />
          Reducir el consumo energético del celular ayuda a disminuir las
          emisiones de gases de efecto invernadero asociadas con la producción y
          el uso de electricidad. Este pequeño gesto, sumado por muchas
          personas, puede contribuir a la mitigación del cambio climático.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/15_hd4qmx.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 15: Vida de Ecosistemas Terrestres: (Meta 15.5) </span>{" "}
          <br />
          Menor consumo energético y menos desechos electrónicos ayudan a
          reducir el impacto negativo sobre los hábitats naturales y la
          biodiversidad. La minería y la producción de componentes electrónicos
          pueden tener efectos devastadores sobre los ecosistemas terrestres.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/17_e8x3cm.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 17: Alianzas para lograr los objetivos: (Meta 17.16) </span>{" "}
          <br />
          Fomentar prácticas sostenibles como apagar el celular puede servir
          como ejemplo y promover la cooperación y el intercambio de mejores
          prácticas a nivel comunitario, nacional e internacional. Las
          iniciativas y alianzas que surjan de estos hábitos pueden fortalecer
          los esfuerzos globales hacia un desarrollo más sostenible.
        </p>
      </div>
    </div>
  );
};

export default OdsActivity6;
