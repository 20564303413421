import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { useUser } from "../context/providers/userContext";
import { useComments } from "../context/providers/commentsContext";
import OldComments from "./ui/OldComments";
import toast from "react-hot-toast";
import CommentForm from "./ui/FormCommentsContent";
import { useTareas } from "../context/providers/tareasContext";
import { Spinner } from "./ui/Spinner";

const TareasRandom = ({ tarea }) => {
  const { isLoggedIn, isAdmin } = useUser();
  const { isLoadingComment, createComment, getCommentsTarea, errorMessage, deleteComment } =
    useComments();
  const { isloading } = useTareas();

  const [comment, setComment] = useState({
    content: "",
    like: "",
    tareaId: "",
  });

  const [comments, setComments] = useState([]);

  const handleSubmit = async (values) => {
    try {
      values.tareaId = tarea._id;
      const result = await createComment(values);
      if (result) {
        const newComments = await getCommentsTarea(tarea._id);
        setComments(newComments);
        toast.success("Comentario agregado", {
          position: "bottom-right",
        });
      }
    } catch (error) {
      toast.error(errorMessage, {
        position: "bottom-right",
      });
    }
  };

  const handleDelete = async (e, id) => {
    e.preventDefault();
    try {
      if (isAdmin) {
        const result = await deleteComment(id);
        if (result) {
          const comments = await getCommentsTarea(tarea._id);
      setComments(comments);
          toast.success(`Se ha eliminado el comentario con exito`, {
            position: "bottom-right",
          });
        }
      }
    } catch (error) {
      toast.error(errorMessage, {
        position: "bottom-right",
      });
    }
  };

  useEffect(() => {
    const getComments = async () => {
      const comments = await getCommentsTarea(tarea._id);
      setComments(comments);
    };
    getComments();
  }, [tarea._id]);

  return (
    <>
      {isloading ? (
        Spinner
      ) : (
        <>
          {tarea.number !== 18 ? (
            <div className="tarea-random-container">
              <img
                className="imagen-fondo-tarea"
                src={
                  tarea && tarea.image && tarea.image.url
                    ? tarea.image.url
                    : "https://res.cloudinary.com/dd8a6qc45/image/upload/v1690574586/mundo_ldar8i.png"
                }
                alt="Imagen de tarea"
              />
              <div className="other-activities-container">
                <div className="head-content-card">
                  <Link to={`/profile/${tarea.user[0]?._id}`}>
                    <div className="img-card-perfil">
                      <img
                        src={
                          tarea.user[0]?.image?.url ||
                          "https://res.cloudinary.com/dd8a6qc45/image/upload/v1690634531/Volunteers/perfilImage/account_e6qpmh.svg"
                        }
                        alt="Imagen de perfil"
                      />
                    </div>
                  </Link>
                  <Link to={`/profile/${tarea.user[0]?._id}`}>
                    <div className="name-perfil">{tarea.user[0]?.name}</div>
                  </Link>
                </div>
                <div className="main-content-card">
                  <div className="title-activity">
                    <h1>{tarea?.description}</h1>
                  </div>
                  <div className="image-activity">
                    {tarea?.image?.url ? (
                      <Link
                        to={`/image/${encodeURIComponent(tarea?.image?.url)}`}
                      >
                        <img src={tarea?.image?.url} alt="Imagen Actividad" />
                      </Link>
                    ) : (
                      <img
                        src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1708746622/Volunteers/mundo_mkg38i.png"
                        alt="Imagen Actividad"
                      />
                    )}
                  </div>

                  <div className="footer-content-card">
                    <div className="form-content-card">
                      {isLoggedIn ? (
                        <CommentForm
                          onSubmit={handleSubmit}
                          comment={comment}
                          des={isLoadingComment}
                        />
                      ) : (
                        <div></div>
                      )}
                    </div>

                    {tarea?.comment[0] ? (
                      <OldComments tarea={tarea} comments={comments} onDelete={handleDelete}/>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};
export default TareasRandom;
