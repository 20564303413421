export const userActions = {
  USER_SIGNIN: "USER_SIGNIN",
  USER_SIGNIN_SUCCESS: "USER_SIGNIN_SUCCESS",
  USER_SIGNIN_ERROR: "USER_SIGNIN_ERROR",

  USER_SIGNUP: "USER_SIGNUP",
  USER_SIGNUP_SUCCESS: "USER_SIGNUP_SUCCESS",
  USER_SIGNUP_ERROR: "USER_SIGNUP_ERROR",

  GET_USER_ID: "GET_USER_ID",
  GET_USER_ID_SUCCESS: "GET_USER_ID_SUCCESS",
  GET_USER_ID_ERROR: "GET_USER_ID_ERROR",

  USER_LOGOUT: "USER_LOGOUT",

  USER_UPDATE: "USER_UPDATE",
  USER_UPDATE_SUCCESS: "USER_UPDATE_SUCCESS",
  USER_UPDATE_ERROR: "USER_UPDATE_ERROR",

  LOAD_TAREAS_USER: "LOAD_TAREAS_USER",
  LOAD_TAREAS_USER_SUCCESS: "LOAD_TAREAS_USER_SUCCESS",
  LOAD_TAREAS_USER_ERROR: "LOAD_TAREAS_USER_ERROR",

  GET_COMMENTS_USER: "GET_COMMENTS_USER",
  GET_COMMENTS_USER_SUCCESS: "GET_COMMENTS_USER_SUCCESS",
  GET_COMMENTS_USER_ERROR: "GET_COMMENTS_USER_ERROR",

  GET_USERS: "GET_USERS",
  GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
  GET_USERS_ERROR: "GET_USERS_ERROR",

  SEND_REVIEW: "SEND_REVIEW",
  SEND_REVIEW_SUCCESS: "SEND_REVIEW_SUCCESS",
  SEND_REVIEW_ERROR: "SEND_REVIEW_ERROR",

  DELETE_COMMENT_ADMIN: "DELETE_COMMENT_ADMIN",
  DELETE_COMMENT_ADMIN_SUCCESS: "DELETE_COMMENT_ADMIN_SUCCESS",
  DELETE_COMMENT_ADMIN_ERROR: "DELETE_COMMENT_ADMIN_ERROR",

  DELETE_USER_ADMIN: "DELETE_USER_ADMIN",
  DELETE_USER_ADMIN_SUCCESS: "DELETE_USER_ADMIN_SUCCESS",
  DELETE_USER_ADMIN_ERROR: "DELETE_USER_ADMIN_ERROR",

  USER_UPDATE_ADMIN: "USER_UPDATE_ADMIN",
  USER_UPDATE_ADMIN_SUCCESS: "USER_UPDATE_ADMIN_SUCCESS",
  USER_UPDATE_ADMIN_ERROR: "USER_UPDATE_ADMIN_ERROR",

  SEND_EMAIL_PASSWORD: "SEND_EMAIL_PASSWORD",
  SEND_EMAIL_PASSWORD_SUCCESS: "SEND_EMAIL_PASSWORD_SUCCESS",
  SEND_EMAIL_PASSWORD_ERROR: "SEND_EMAIL_PASSWORD_ERROR",

  PASSWORD_RESET: "PASSWORD_RESET",
  PASSWORD_RESET_SUCCESS: "PASSWORD_RESET_SUCCESS",
  PASSWORD_RESET_ERROR: "PASSWORD_RESET_ERROR",

  UPDATE_PASSWORD: "UPDATE_PASSWORD",
  UPDATE_PASSWORD_SUCCESS: "UPDATE_PASSWORD_SUCCESS",
  UPDATE_PASSWORD_ERROR: "UPDATE_PASSWORD_ERROR",

  UPDATE_MYUSER: "UPDATE_MYUSER",
  UPDATE_MYUSER_SUCCESS: "UPDATE_MYUSER_SUCCESS",
  UPDATE_MYUSER_ERROR: "UPDATE_MYUSER_ERROR",

  DELETE_MY_ACCOUNT: "DELETE_MY_ACCOUNT",
  DELETE_MY_ACCOUNT_SUCCESS: "DELETE_MY_ACCOUNT_SUCCESS",
  DELETE_MY_ACCOUNT_ERROR: "DELETE_MY_ACCOUNT_ERROR",

  GET_USER_CHAT: "GET_USER_CHAT",
  GET_USER_CHAT_SUCCESS: "GET_USER_CHAT_SUCCESS",
  GET_USER_CHAT_ERROR: "GET_USER_CHAT_ERROR",
};
