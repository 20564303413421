import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCheck } from "@fortawesome/free-solid-svg-icons";
import { faUnlockKeyhole } from "@fortawesome/free-solid-svg-icons";
import { faArrowAltCircleRight } from "@fortawesome/free-regular-svg-icons";

import { useUser } from "../../context/providers/userContext";
import { Spinner } from "../../components/ui/Spinner";
import("../../styles/login.css");

const Login = () => {
  const { postSignIn, isLoading } = useUser();

  const [us, setUs] = useState({
    email: "",
    password: "",
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    setUs({ ...us, [e.target.name]: e.target.value });
  };

  const isPasswordValid = us.password.length >= 6;

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const signInSuccessful = await postSignIn(us);
      if (signInSuccessful) {
        navigate("/content");
        toast.success("Sesion iniciada exitosamente!", {
          position: "bottom-right",
        });
      } else {
        toast.error("Credenciales invalidas. Intente nuevamente.", {
          position: "bottom-right",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <main className="main">
      <section className="section-login">
        {isLoading ? (
          Spinner
        ) : (
          <>
            <div className="logopage">
              <img
                src="https://i.postimg.cc/hPFTzTn1/TRIANGULO-NEGRO-VOLUNTEERS-720p.png"
                alt="logo"
              />
            </div>
            <div className="form">
              <h2>Hola Volunteers!</h2>
              <h2>Inicie sesion para continuar</h2>
              <div className="form">
                <Formik
                  initialValues={us}
                  validationSchema={Yup.object({
                    email: Yup.string()
                      .required("Ingrese su correo.")
                      .email("Debe ser un email valido.")
                      .matches(
                        /^[^@]+@[^@]+\..+$/,
                        "Debe ser un email valido."
                      ),
                    password: Yup.string()
                      .required("Ingrese su contraseña.")
                      .min(6, "La contraseña debe tener minimo 6 caracteres."),
                  })}
                  onSubmit={handleSubmit}
                  enableReinitialize={true}
                  className="form"
                >
                  <Form onSubmit={handleSubmit}>
                    <div className="input-label">
                      <div className="img-icon-user">
                        <FontAwesomeIcon className="icono" icon={faUserCheck} />
                      </div>
                      <Field
                        className="input-login"
                        name="email"
                        type="text"
                        onChange={handleChange}
                        required
                        autoComplete="current-email"
                      />
                      <label className="label-login" htmlFor="email">
                        Email
                      </label>
                      <ErrorMessage
                        className="error-message"
                        component="p"
                        name="email"
                      />
                    </div>
                    <div className="input-label">
                      <div className="img-icon-password">
                        <FontAwesomeIcon
                          className="icono"
                          icon={faUnlockKeyhole}
                        />
                        <img src="" alt="" />
                      </div>
                      <Field
                        className="input-login"
                        name="password"
                        type="password"
                        onChange={handleChange}
                        required
                        autoComplete="current-password"
                      />
                      <label className="label-login" htmlFor="password">
                        Contraseña
                      </label>
                      <ErrorMessage
                        className="error-message"
                        component="p"
                        name="password"
                      />
                    </div>
                    <div className="buttons">
                      <button
                        className="button-enter"
                        type="submit"
                        disabled={
                          !us.email ||
                          !us.password ||
                          isLoading ||
                          !isPasswordValid
                        }
                      >
                        {isLoading ? "Cargando" : "Actua por el planeta"}
                        <FontAwesomeIcon
                          className="icono icon-enter"
                          icon={faArrowAltCircleRight}
                        />
                      </button>
                      <Link to="/signup" className="">
                        <button className="button-signin">REGISTRARSE</button>
                      </Link>
                      <Link
                        to="/send-password"
                        className="terminosycondiciones"
                      >
                        Olvidaste tu contraseña?
                      </Link>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </>
        )}
      </section>
    </main>
  );
};

export default Login;
