import "../../styles/spinner.css";

export const Spinner = (
  <div className="spinner-container">
    <div className="div-spinner">
      <img
        className="spinner"
        src="https://i.postimg.cc/hPFTzTn1/TRIANGULO-NEGRO-VOLUNTEERS-720p.png"
        alt="Logo volunteers"
      />
    </div>
  </div>
);
