import React, { useEffect } from "react";
import { useUser } from "../../context/providers/userContext";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCameraRetro } from "@fortawesome/free-solid-svg-icons";

const AccountPerfil = ({ invited }) => {
  const { user, getUserId } = useUser();

  const start = () => {
    if (invited) {
      getUserId(user._id);
    } else {
    }
  };

  useEffect(() => {
    start();
  }, []);

  return (
    <section className="head-perfil">
      <h1>{user?.name}</h1>
      <div className="img-fondo">
        <img
          src="https://images.pexels.com/photos/1324803/pexels-photo-1324803.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
          alt="Imagen de fondo"
        />
      </div>
      <div className="img-perfil-card">
        <div className="img-perfil">
          <img
            src={
              user?.image && user?.image?.url
                ? user?.image?.url
                : "https://res.cloudinary.com/dd8a6qc45/image/upload/v1690634531/Volunteers/perfilImage/account_e6qpmh.svg"
            }
            alt="Foto de perfil"
          />
          {!invited ? (
            <div className="update-perfil">
              <Link to={`/update-perfil/${user?._id}`}>
                <FontAwesomeIcon icon={faCameraRetro} />
              </Link>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </section>
  );
};

export default AccountPerfil;
