import axios from "axios";

import { apiURL } from "./apiConfig";

export const getCodesRequest = async () => {
  try {
    return await axios.get(`${apiURL}/api/code`);
  } catch (error) {
    console.log(error);
  }
};

export const getCodeRequest = async (id) => {
  try {
    return await axios.get(`${apiURL}/api/code/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const postCodeRequest = async () => {
  try {
    return await axios.post(`${apiURL}/api/code`);
  } catch (error) {
    console.log(error);
  }
};

export const putCodeRequest = async (id) => {
  try {
    return await axios.put(`${apiURL}/api/code/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const deleteCodeRequest = async (id) => {
  try {
    return await axios.delete(`${apiURL}/api/code/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const verifyCodeRequest = async (code) => {
  try {
    const result = { code: code };
    return await axios.post(`${apiURL}/api/code/verify-code`, result);
  } catch (error) {
    throw error.response.data.message;
  }
};
