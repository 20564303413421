import React, { useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCameraRetro } from "@fortawesome/free-solid-svg-icons";

import "../../styles/accountFree.css";
import { useUser } from "../../context/providers/userContext";
import Activity from "../../components/ui/Activity";
import { Spinner } from "../../components/ui/Spinner";
import { useActivities } from "../../context/providers/activitiesContext";
import toast from "react-hot-toast";
import Exam from "../../components/Exam";

const AccountFree = () => {
  const {
    userTareas,
    user,
    getUserTareas,
    isLoading,
    updateUser,
    updateMyUser,
  } = useUser();

  const { activities, isLoading: isLoadingActivities } = useActivities();

  var userInvited = false;

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const us = user;
      us.complete = 1;
      const userResponse = await updateUser(user._id, us);
      if (userResponse) {
        toast.success("Tareas enviadas satisfactoriamente!", {
          position: "bottom-right",
        });
      }
    } catch (error) {
      toast.error("Tareas enviadas satisfactoriamente!", {
        position: "bottom-right",
      });
      console.log(error);
    }
  };

  const navigate = useNavigate();
  const location = useLocation();

  const prevScrollY = useRef(0);

  useEffect(() => {
    prevScrollY.current = window.scrollY;

    return () => {
      prevScrollY.current = 0;
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, prevScrollY.current);
  }, [location.pathname]);

  useEffect(() => {
    const start = async () => {
      await updateMyUser(user?._id);
      await getUserTareas(user?._id);
    };
    start();
  }, []);

  return (
    <main className="main-accountfree">
      {isLoading ? (
        Spinner
      ) : (
        <>
          <div className="accountfree-container">
            <section className="head-perfil">
              <div className="img-fondo">
                <img
                  src="https://images.pexels.com/photos/1324803/pexels-photo-1324803.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                  alt="Imagen de fondo"
                />
              </div>
              <div className="img-perfil-card">
                <div className="img-perfil">
                  <img
                    src={
                      user?.image && user?.image?.url
                        ? user?.image?.url
                        : "https://res.cloudinary.com/dd8a6qc45/image/upload/v1690634531/Volunteers/perfilImage/account_e6qpmh.svg"
                    }
                    alt="Foto de perfil"
                  />
                  <h1 className="title-name-user-perfil">{user?.name}</h1>

                  {!userInvited ? (
                    <div className="update-perfil">
                      <Link to={`/update-perfil/${user?._id}`}>
                        <FontAwesomeIcon icon={faCameraRetro} />
                      </Link>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </section>

            <section className="cards">
              <a className="button-down-account-free" href="#down">
                <div>Continuar</div>
              </a>

              {isLoadingActivities ? (
                Spinner
              ) : (
                <>
                  <article className="activity">
                    {activities.map((activity) => (
                      <div key={activity._id}>
                        {userTareas.map((userTarea) => (
                          <div key={`${activity._id}-${userTarea._id}`}>
                            {userTarea.number === activity.number ? (
                              <div key={userTarea._id}>
                                {activity.number === 1 ? (
                                  <div>
                                    <Activity
                                      tarea={activity}
                                      userTarea={userTarea}
                                    />
                                    <div className="arrow-down-container"></div>
                                  </div>
                                ) : (
                                  <></>
                                )}
                                {activity.number >= 2 &&
                                activity.number <= 17 ? (
                                  userTareas[userTarea.number - 2].image ? (
                                    <div>
                                      <Activity
                                        tarea={activity}
                                        userTarea={userTarea}
                                      />
                                      <div className="arrow-down-container"></div>
                                    </div>
                                  ) : (
                                    <></>
                                  )
                                ) : (
                                  <></>
                                )}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        ))}
                      </div>
                    ))}
                    {userTareas[17]?.image ? (
                      <div>
                        <Exam
                          tarea={activities[17]}
                          userTarea={userTareas[17]}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="mark-bottom" id="down">
                      <p className="mark-volunteers">VOLUNTEERS</p>
                    </div>
                  </article>
                  <div className="pdf">
                    {user.complete === 3 ? (
                      <Link
                        className="button-certificado-volunteers"
                        id="certificado"
                        to={`/certificado-volunteers/${user._id}`}
                      >
                        OBTEN TU CERTIFICADO
                      </Link>
                    ) : (
                      <>
                        <div className="note-admin">{user?.adminNote}</div>
                        <button
                          className="button-certificado-volunteers"
                          onClick={(e) => {
                            handleSubmit(e);
                          }}
                        >
                          Enviar Terminado
                        </button>
                      </>
                    )}
                  </div>
                </>
              )}
            </section>
          </div>
        </>
      )}
    </main>
  );
};

export default AccountFree;
