import React, { useEffect, useRef, useState } from "react";
import html2pdf from "html2pdf.js";

import { useUser } from "../context/providers/userContext";
import "../styles/certificado.css";
import imgCertificado from "../assets/imgCertificado.png";

const CertificadoVolunteers = () => {
  const [currentDataTime, setCurrentDataTime] = useState(new Date());

  const { user } = useUser();
  const contentRef = useRef(null);

  const handleSubmit = async () => {
    const content = contentRef.current;

    const opt = {
      margin: [5, 10, 5, 10],
      filename: `certificado${user.document}.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: { scale: 1 },
      jsPDF: {
        unit: "mm",
        format: "a4",
        orientation: "landscape",
      },
    };
    await html2pdf().from(content).set(opt).save();
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDataTime(new Date());
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const ops = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    // weekday: 'long',
    // hour: 'numeric',
    // minute: 'numeric',
    // second: 'numeric',
  };

  const formattedDateTime = currentDataTime.toLocaleDateString("es-Es", ops);

  return (
    <div>
      <div className="hidden">
        <div className="buttons box-botton">
          <button className=" button-signin" onClick={handleSubmit}>
            Generar Certificado
          </button>
        </div>
      </div>
      <div className="overflow">
        <div className="certificado" id="exp-pdf" ref={contentRef}>
          <img
            className="certificado-img"
            src={imgCertificado}
            alt="Certificado"
          />
          <span className="certificado-span">
            La fundación <b>DASOS</b> certifica que <br />
            <p className="user-name">{user.name}</p>
            identificado con documento {user.document} <br />
            concluyó satisfactoriamente la formación.
          </span>
          <div className="certificado-title-volunteer">
            <h1 className="volunteers">VOLUNTEER</h1>
          </div>
          <div className="certificado-footer">
            <span className="certificado-span">
              equivalente a 180 horas de trabajo social y ambiental comunitario
            </span>
          </div>
          <div className="firma-registro">
            <div className="firma">
              <span className="firma-name">JAIME ENRIQUE CUERVO</span>
              <br />
              <span className="firma-cargo">Representante legal</span>
            </div>
            <div className="registro">
              <span className="certificado-span">
                Número de Registro:
                {user.numberCertificate || user.document} <br />
                Fecha de expedición: {formattedDateTime}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CertificadoVolunteers;
