import { createContext, useContext, useReducer } from "react";

import {
  postSignInRequest,
  postSignUpRequest,
  getProfileRequest,
  putUserRequest,
  getTareasUserRequest,
  getUserRequest,
  getUsers,
  deleteUserAndTasksRequest,
  emailResetPasswordRequest,
  resetPasswordRequest,
  updatePasswordRequest,
  deleteMyAccountRequest,
} from "../../api/users";
import { userReducer, initialState } from "../reducer/userReducer";
import { userActions } from "../actions/userActions";
import { io } from "socket.io-client";
import { apiIoURL, apiURL } from "../../api/apiConfig";

export const userContext = createContext(initialState);

export const useUser = () => {
  const context = useContext(userContext);
  if (!context) throw new Error("must be in an AuthProvider");
  return context;
};

export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, initialState);

  const getAllUsers = async () => {
    dispatch({ type: userActions.GET_USERS });
    try {
      const res = await getUsers();
      dispatch({
        type: userActions.GET_USERS_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: userActions.GET_USERS_ERROR,
        payload: error.message,
      });
    }
  };

  const getUserId = async (id) => {
    dispatch({ type: userActions.GET_USER_ID });
    try {
      const res = await getUserRequest(id);
      dispatch({
        type: userActions.GET_USER_ID_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } catch (error) {
      dispatch({
        type: userActions.GET_USER_ID_ERROR,
        payload: error.message,
      });
    }
  };

  const getUserIdChat = async (id) => {
    dispatch({ type: userActions.GET_USER_CHAT });
    try {
      const res = await getUserRequest(id);
      dispatch({
        type: userActions.GET_USER_CHAT_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } catch (error) {
      dispatch({
        type: userActions.GET_USER_CHAT_ERROR,
        payload: error.message,
      });
    }
  };

  const postUser = async (user) => {
    dispatch({ type: userActions.USER_SIGNUP });
    try {
      const res = await postSignUpRequest(user);
      const { token } = res.data;

      localStorage.setItem("token", token);
      if (token) {
        const resUser = await getProfileRequest(token);
        localStorage.setItem("user", JSON.stringify(resUser.data));
        dispatch({
          type: userActions.USER_SIGNUP_SUCCESS,
          payload: {
            token,
            user: resUser.data,
          },
        });
        return resUser.data;
      }
    } catch (error) {
      if (error.response.data) {
        dispatch({
          type: userActions.USER_SIGNUP_ERROR,
          payload: error.response.data.message,
        });
      }
    }
  };

  const postSignIn = async ({ email, password }) => {
    dispatch({ type: userActions.USER_SIGNIN });
    try {
      const res = await postSignInRequest({ email, password });
      const { token } = res.data;

      localStorage.setItem("token", token);
      if (token) {
        const resUser = await getProfileRequest(token);
        const userId = resUser.data._id;
        localStorage.setItem("user", JSON.stringify(resUser.data));
        // socket.emit('userId', resUser.data._id);
        // const socket = io(apiIoURL, {
        //   query: { userId },
        //   reconnection: true,
        //   reconnectionAttempts: 10,
        //   reconnectionDelay: 2000,
        //   reconnectionDelayMax: 5000
        // });
        const isAdmin = resUser.data.roles.some(
          (role) => role.name === "admin"
        );
        dispatch({
          type: userActions.USER_SIGNIN_SUCCESS,
          payload: {
            token,
            user: resUser.data,
            isAdmin: isAdmin,
            // socket: socket,
          },
        });

        return resUser.data;
      }
    } catch (error) {
      if (error) {
        dispatch({
          type: userActions.USER_SIGNIN_ERROR,
          payload: error,
        });
      }
    }
  };

  const logout = async () => {
    localStorage.clear();
    dispatch({ type: userActions.USER_LOGOUT });
  };

  const updateUser = async (id, user) => {
    dispatch({ type: userActions.USER_UPDATE });
    try {
      const res = await putUserRequest(id, user);
      localStorage.setItem("user", JSON.stringify(res.data));
      dispatch({
        type: userActions.USER_UPDATE_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } catch (error) {
      dispatch({
        type: userActions.USER_UPDATE_ERROR,
        payload: error.message,
      });
      console.log(error);
    }
  };

  const updateUserAdmin = async (id, user) => {
    dispatch({ type: userActions.USER_UPDATE_ADMIN });
    try {
      const res = await putUserRequest(id, user);
      const resUser = await getUserRequest(id);
      dispatch({
        type: userActions.USER_UPDATE_ADMIN_SUCCESS,
        payload: resUser.data,
      });
      return res.data;
    } catch (error) {
      dispatch({
        type: userActions.USER_UPDATE_ADMIN_ERROR,
        payload: error.message,
      });
      console.log(error);
    }
  };

  const sendReview = async (id, user) => {
    dispatch({ type: userActions.SEND_REVIEW });
    try {
      const res = await putUserRequest(id, user);
      localStorage.setItem("user", JSON.stringify(res.data));
      console.log(res.data);
      dispatch({
        type: userActions.SEND_REVIEW_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } catch (error) {
      dispatch({
        type: userActions.SEND_REVIEW_ERROR,
        payload: error.message,
      });
      console.log(error);
    }
  };

  const getUserTareas = async (id) => {
    dispatch({
      type: userActions.LOAD_TAREAS_USER,
    });
    try {
      if (id) {
        const res = await getTareasUserRequest(id);
        await getUserRequest(id);
        if (res.data) {
          dispatch({
            type: userActions.LOAD_TAREAS_USER_SUCCESS,
            payload: {
              userTareas: res.data,
            },
          });
        }
      }
    } catch (error) {
      dispatch({
        type: userActions.LOAD_TAREAS_USER_ERROR,
        payload: error.message,
      });
    }
  };

  const deleteUserAndTaksAdmin = async (idUser) => {
    dispatch({ type: userActions.DELETE_USER_ADMIN });
    try {
      const res = await deleteUserAndTasksRequest(idUser);
      if (res) {
        dispatch({
          type: userActions.DELETE_USER_ADMIN_SUCCESS,
          payload: idUser,
        });
      }
      return res.data;
    } catch (error) {
      dispatch({
        type: userActions.DELETE_USER_ADMIN_ERROR,
        payload: error.message,
      });
    }
  };

  const emailResetPassword = async (email) => {
    dispatch({ type: userActions.SEND_EMAIL_PASSWORD });
    try {
      const res = await emailResetPasswordRequest(email);
      if (res) {
        dispatch({
          type: userActions.SEND_EMAIL_PASSWORD_SUCCESS,
          payload: email,
        });
      }
    } catch (error) {
      dispatch({
        type: userActions.SEND_EMAIL_PASSWORD_ERROR,
        payload: error.message,
      });
    }
  };

  const resetPassword = async (token) => {
    dispatch({ type: userActions.PASSWORD_RESET });
    try {
      const res = await resetPasswordRequest(token);
      dispatch({
        type: userActions.PASSWORD_RESET_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } catch (error) {
      dispatch({
        type: userActions.PASSWORD_RESET_ERROR,
        payload: error,
      });
    }
  };

  const updatePassword = async (id, user) => {
    dispatch({ type: userActions.UPDATE_PASSWORD });
    try {
      const res = await updatePasswordRequest(id, user);
      dispatch({
        type: userActions.UPDATE_PASSWORD_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } catch (error) {
      dispatch({
        type: userActions.UPDATE_PASSWORD_ERROR,
        payload: error,
      });
    }
  };

  const updateMyUser = async (id) => {
    dispatch({ type: userActions.UPDATE_MYUSER });
    try {
      const res = await getUserRequest(id);
      if (res.data) {
        localStorage.setItem("user", JSON.stringify(res.data));
        dispatch({
          type: userActions.UPDATE_MYUSER_SUCCESS,
          payload: res.data,
        });
      }
      return res.data;
    } catch (error) {
      dispatch({
        type: userActions.UPDATE_MYUSER_ERROR,
        payload: error.message,
      });
    }
  };

  const deleteMyAccount = async (id) => {
    dispatch({ type: userActions.DELETE_MY_ACCOUNT });
    try {
      const res = await deleteMyAccountRequest(id);
      dispatch({
        type: userActions.DELETE_MY_ACCOUNT_SUCCESS,
      });
      return res.data;
    } catch (error) {
      dispatch({
        type: userActions.DELETE_MY_ACCOUNT_ERROR,
        payload: error.message,
      });
    }
  };

  return (
    <userContext.Provider
      value={{
        ...state,
        postUser,
        postSignIn,
        logout,
        updateUser,
        getUserTareas,
        getUserId,
        getUserIdChat,
        getAllUsers,
        sendReview,
        deleteUserAndTaksAdmin,
        updateUserAdmin,
        emailResetPassword,
        resetPassword,
        updatePassword,
        updateMyUser,
        deleteMyAccount,
      }}
    >
      {children}
    </userContext.Provider>
  );
};
