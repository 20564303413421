import React from "react";
import "../styles/ods.css";

const Ods = () => {
  return (
    <div className="ods-container">
      <h2>OBJETIVOS DE DESARROLLO SOSTENIBLE</h2>
      <div>
        <p>
          <span>Metas ODS relacionadas con actividades de Volunteers:</span>
          <br />
          <br />
          El programa Volunteers es un proyecto de educación y activismo
          ambiental que cumple con los 17 Objetivos de Desarrollo Sostenible
          (ODS) y abarca 43 metas, teniendo un impacto transformador tanto en la
          sociedad como en el medio ambiente y confiriéndole una naturaleza
          completamente holística y completa para la acción climática local.
          Este tipo de proyecto promueve la concienciación y el cambio de
          comportamiento hacia prácticas sostenibles, lo que resulta en la
          mejora de la salud pública, la reducción de la pobreza y el hambre, la
          promoción de la igualdad de género, y la mejora de la calidad de la
          educación. Además, fomenta la adopción de energías renovables, la
          conservación de los recursos hídricos, y la protección de los
          ecosistemas terrestres y acuáticos. Al involucrar a diversas
          comunidades en la acción climática y en la gestión sostenible de los
          recursos, se fortalece la resiliencia frente al cambio climático y se
          fomenta una economía circular que reduce residuos y promueve la
          eficiencia en el uso de recursos. En conjunto, estos beneficios
          contribuyen a la creación de sociedades más inclusivas, sostenibles y
          resilientes, capaces de enfrentar los desafíos globales del siglo XXI.
        </p>
        <br />
      </div>
      <div>
        <p>
          <span>1. Meta 1.5: </span> Para 2030, construir la resiliencia de los
          pobres y aquellos en situaciones vulnerables, y reducir su exposición
          y vulnerabilidad a eventos extremos relacionados con el clima y otros
          desastres económicos, sociales y ambientales.
        </p>
        <br />
      </div>
      <div>
        <p>
          <span>2. Meta 2.2: </span> Poner fin a todas las formas de
          malnutrición.
        </p>
        <br />
      </div>
      <div>
        <p>
          <span>3. Meta 2.3: </span> Duplicar la productividad agrícola y los
          ingresos de los productores de alimentos en pequeña escala.
        </p>
        <br />
      </div>
      <div>
        <p>
          <span>4. Meta 2.4: </span> Para 2030, asegurar la sostenibilidad de
          los sistemas de producción de alimentos y aplicar prácticas agrícolas
          resilientes que aumenten la productividad y la producción, contribuyan
          al mantenimiento de los ecosistemas, fortalezcan la capacidad de
          adaptación al cambio climático, los fenómenos extremos, la sequía, las
          inundaciones y otros desastres, y mejoren progresivamente la calidad
          del suelo y la tierra.
        </p>
        <br />
      </div>
      <div>
        <p>
          <span>5. Meta 3.4: </span> Reducir en un tercio la mortalidad
          prematura por enfermedades no transmisibles mediante la prevención y
          el tratamiento, y promover la salud mental y el bienestar.
        </p>
        <br />
      </div>
      <div>
        <p>
          <span>6. Meta 3.9: </span> Para 2030, reducir sustancialmente el
          número de muertes y enfermedades producidas por productos químicos
          peligrosos y por la contaminación del aire, el agua y el suelo.
        </p>
        <br />
      </div>
      <div>
        <p>
          <span>7. Meta 4.7: </span> Para 2030, asegurar que todos los alumnos
          adquieran los conocimientos teóricos y prácticos necesarios para
          promover el desarrollo sostenible, entre otros mediante la promoción
          de estilos de vida sustentables, los derechos humanos, la igualdad de
          género, la promoción de una cultura de paz y no violencia, la
          ciudadanía mundial y la valoración de la diversidad cultural y la
          contribución de la cultura al desarrollo sostenible.
        </p>
        <br />
      </div>
      <div>
        <p>
          <span>8. Meta 5.B: </span> Mejorar el uso de la tecnología
          instrumental, en particular la tecnología de la información y las
          comunicaciones, para promover el empoderamiento de la mujer.
        </p>
        <br />
      </div>
      <div>
        <p>
          <span>9. Meta 6.1: </span> Garantizar el acceso universal y equitativo
          a agua potable a un precio asequible para todos.
        </p>
        <br />
      </div>
      <div>
        <p>
          <span>10. Meta 6.3: </span> Para 2030, mejorar la calidad del agua
          mediante la reducción de la contaminación, la eliminación de vertidos
          y la minimización de la liberación de productos químicos y materiales
          peligrosos, reduciendo a la mitad el porcentaje de aguas residuales no
          tratadas y aumentando considerablemente el reciclado y la
          reutilización segura del agua a nivel mundial.
        </p>
        <br />
      </div>
      <div>
        <p>
          <span>11. Meta 6.4: </span> Aumentar significativamente la eficiencia
          del uso del agua en todos los sectores y asegurar extracciones
          sostenibles de agua dulce.
        </p>
        <br />
      </div>
      <div>
        <p>
          <span>12. Meta 6.5: </span> Implementar la gestión integrada de los
          recursos hídricos a todos los niveles.
        </p>
        <br />
      </div>
      <div>
        <p>
          <span>13. Meta 6.6: </span> Proteger y restablecer los ecosistemas
          relacionados con el agua, incluidos los bosques, las montañas, los
          humedales, los ríos, los acuíferos y los lagos.
        </p>
        <br />
      </div>
      <div>
        <p>
          <span>14. Meta 7.3: </span> Para 2030, duplicar la tasa mundial de
          mejora de la eficiencia energética.
        </p>
        <br />
      </div>
      <div>
        <p>
          <span>15. Meta 8.4: </span> Mejorar progresivamente, hasta 2030, la
          producción y el consumo eficientes de los recursos mundiales y
          procurar desacoplar el crecimiento económico de la degradación del
          medio ambiente. La creación de artículos con materiales reciclados
          promueve un uso más eficiente de los recursos y puede generar
          oportunidades de empleo en el sector del reciclaje y la manufactura
          sostenible.
        </p>
        <br />
      </div>
      <div>
        <p>
          <span>16. Meta 9.1: </span> Desarrollar infraestructuras fiables,
          sostenibles, resilientes y de calidad, incluidas las infraestructuras
          regionales y transfronterizas.
        </p>
        <br />
      </div>
      <div>
        <p>
          <span>17. Meta 9.4: </span> Modernizar la infraestructura y
          reconvertir las industrias para que sean sostenibles, utilizando los
          recursos con mayor eficiencia y adoptando tecnologías y procesos
          industriales limpios y ambientalmente racionales.
        </p>
        <br />
      </div>
      <div>
        <p>
          <span>18. Meta 10.2: </span> Para 2030, empoderar y promover la
          inclusión social, económica y política de todas las personas,
          independientemente de su edad, sexo, discapacidad, raza, etnia,
          origen, religión, situación económica u otra condición.
        </p>
        <br />
      </div>
      <div>
        <p>
          <span>19. Meta 10.3: </span> Garantizar la igualdad de oportunidades y
          reducir las desigualdades de resultados, incluso eliminando leyes,
          políticas y prácticas discriminatorias y promoviendo legislaciones,
          políticas y medidas adecuadas a ese respecto.
        </p>
        <br />
      </div>
      <div>
        <p>
          <span>20. Meta 10.4: </span> Adoptar políticas, especialmente
          fiscales, salariales y de protección social, y lograr progresivamente
          una mayor igualdad.
        </p>
        <br />
      </div>
      <div>
        <p>
          <span>21. Meta 11.2: </span> Para 2030, proporcionar acceso a sistemas
          de transporte seguros, asequibles, accesibles y sostenibles para
          todos, mejorando la seguridad vial, en particular mediante la
          expansión del transporte público.
        </p>
        <br />
      </div>
      <div>
        <p>
          <span>22. Meta 11.4: </span> Redoblar los esfuerzos para proteger y
          salvaguardar el patrimonio cultural y natural del mundo.
        </p>
        <br />
      </div>
      <div>
        <p>
          <span>23. Meta 11.6: </span> Reducir el impacto ambiental negativo per
          cápita de las ciudades, prestando especial atención a la calidad del
          aire y a la gestión de los desechos municipales y de otro tipo.
        </p>
        <br />
      </div>
      <div>
        <p>
          <span>24. Meta 11.7: </span> Para 2030, proporcionar acceso universal
          a zonas verdes y espacios públicos seguros, inclusivos y accesibles,
          en particular para las mujeres y los niños, las personas de edad y las
          personas con discapacidad.
        </p>
        <br />
      </div>
      <div>
        <p>
          <span>25. Meta 12.2: </span> Para 2030, lograr la gestión sostenible y
          el uso eficiente de los recursos naturales.
        </p>
        <br />
      </div>
      <div>
        <p>
          <span>26. Meta 12.3: </span> Para 2030, reducir a la mitad el
          desperdicio de alimentos per cápita mundial en la venta al por menor y
          a nivel de los consumidores, y reducir las pérdidas de alimentos en
          las cadenas de producción y suministro, incluidas las pérdidas
          posteriores a la cosecha.
        </p>
        <br />
      </div>
      <div>
        <p>
          <span>27. Meta 12.5: </span> Reducir considerablemente la generación
          de desechos mediante actividades de prevención, reducción, reciclado y
          reutilización.
        </p>
        <br />
      </div>
      <div>
        <p>
          <span>28. Meta 12.8: </span> Asegurar que las personas de todo el
          mundo tengan la información y los conocimientos necesarios para el
          desarrollo sostenible y un estilo de vida en armonía con la
          naturaleza.
        </p>
        <br />
      </div>
      <div>
        <p>
          <span>29. Meta 13.1: </span> Fortalecer la resiliencia y la capacidad
          de adaptación a los riesgos relacionados con el clima y los desastres
          naturales en todos los países.
        </p>
        <br />
      </div>
      <div>
        <p>
          <span>30. Meta 13.2: </span> Incorporar medidas relativas al cambio
          climático en las políticas, estrategias y planes nacionales.
        </p>
        <br />
      </div>
      <div>
        <p>
          <span>31. Meta 13.3: </span> Mejorar la educación, la sensibilización
          y la capacidad humana e institucional sobre la mitigación del cambio
          climático, la adaptación, la reducción de sus efectos y la alerta
          temprana.
        </p>
        <br />
      </div>
      <div>
        <p>
          <span>32. Meta 14.1: </span> Para 2025, prevenir y reducir
          significativamente la contaminación marina de todo tipo, en particular
          la procedente de actividades terrestres, incluidos los desechos
          marinos y la contaminación por nutrientes.
        </p>
        <br />
      </div>
      <div>
        <p>
          <span>33. Meta 14.2: </span> Gestionar y proteger de manera sostenible
          los ecosistemas marinos y costeros para evitar efectos adversos
          significativos.
        </p>
        <br />
      </div>
      <div>
        <p>
          <span>34. Meta 14.4: </span> Reglamentar eficazmente la recolección y
          poner fin a la sobrepesca, la pesca ilegal, no declarada y no
          reglamentada y las prácticas de pesca destructivas y aplicar planes de
          gestión basados en datos científicos.
        </p>
        <br />
      </div>
      <div>
        <p>
          <span>35. Meta 15.1: </span> Asegurar la conservación, el
          restablecimiento y el uso sostenible de los ecosistemas terrestres y
          de agua dulce y los servicios que proporcionan, en particular los
          bosques, humedales, montañas y tierras secas.
        </p>
        <br />
      </div>
      <div>
        <p>
          <span>36. Meta 15.2: </span> Promover la gestión sostenible de todos
          los tipos de bosques, detener la deforestación, restaurar los bosques
          degradados y aumentar considerablemente la forestación y la
          reforestación a nivel mundial.sdf
        </p>
        <br />
      </div>
      <div>
        <p>
          <span>37. Meta 15.5: </span> Adoptar medidas urgentes para reducir la
          degradación de los hábitats naturales, detener la pérdida de
          biodiversidad y, proteger y evitar la extinción de especies
          amenazadas.
        </p>
        <br />
      </div>
      <div>
        <p>
          <span>38. Meta 15.7: </span> Adoptar medidas urgentes para poner fin a
          la caza furtiva y el tráfico de especies de flora y fauna protegidas y
          abordar tanto la oferta como la demanda de productos ilegales de fauna
          y flora silvestres.
        </p>
        <br />
      </div>
      <div>
        <p>
          <span>39. Meta 15.9: </span> Integrar los valores de los ecosistemas y
          la biodiversidad en la planificación nacional y local, en los procesos
          de desarrollo.
        </p>
        <br />
      </div>
      <div>
        <p>
          <span>40. Meta 16.1: </span> Reducir significativamente todas las
          formas de violencia y las tasas de mortalidad conexas en todo el
          mundo.
        </p>
        <br />
      </div>
      <div>
        <p>
          <span>41. Meta 16.7: </span> Garantizar la adopción en todos los
          niveles de decisiones inclusivas, participativas y representativas que
          respondan a las necesidades.
        </p>
        <br />
      </div>
      <div>
        <p>
          <span>42. Meta 17.16: </span> Mejorar la Alianza Mundial para el
          Desarrollo Sostenible, complementada por alianzas entre múltiples
          interesados que movilicen y compartan conocimientos, especialización,
          tecnología y recursos financieros, para apoyar el logro de los ODS en
          todos los países, en particular los países en desarrollo.
        </p>
        <br />
      </div>
      <div>
        <p>
          <span>43. Meta 17.17: </span> Fomentar y promover la constitución de
          alianzas eficaces en las esferas pública, público-privada y de la
          sociedad civil, aprovechando la experiencia y las estrategias de
          obtención de recursos de las alianzas.
        </p>
        <br />
      </div>
    </div>
  );
};

export default Ods;