import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Task18Admin = ({ tarea }) => {
  const [resp, setResp] = useState([]);

  useEffect(() => {
    const separate = async () => {
      const question = await tarea?.description.split("####");
      if (question) {
        const formattefQuestions = question.map((question) => {
          return `${question.trim()}`;
        });

        setResp(formattefQuestions);
      }
    };
    separate();
  }, []);
  return (
    <div className="main-content-card-admin">
      <div className="title-activity-admin">
        <h1 className="">{tarea?.title}</h1>
      </div>
      <div className="image-activity">
        {tarea?.image?.url ? (
          <Link to={`/image/${encodeURIComponent(tarea?.image?.url)}`}>
            <img src={tarea?.image?.url} alt="Imagen Actividad" />
          </Link>
        ) : (
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1690574586/mundo_ldar8i.png"
            alt="Imagen Actividad"
          />
        )}
      </div>
      <div className="description-card-tareas">
        <h4>Descripcion:</h4>
        <div id={`activity${tarea?.number}`} className="text-activity">
          {resp.map((q, index) => (
            <div key={index}>
              {index === 5 ? (
                <div className="resp-task18-admin">
                  <span className="number-activity">{index + 1}</span>
                  <p>{q}</p>
                </div>
              ) : (
                <div className="resp-task18-admin">
                  <span className="number-activity">{index + 1}</span>
                  <p>{`${q}?`}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <hr />
    </div>
  );
};

export default Task18Admin;
