import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";

const CommentForm = ({ onSubmit, comment }) => {
  return (
    <Formik
      initialValues={comment}
      validationSchema={Yup.object({
        content: Yup.string(),
      })}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {({ values, isValid }) => (
        <Form>
          <ErrorMessage
            className="error-message"
            component="p"
            name="content"
          />
          <label htmlFor="content">Nuevo comentario</label>
          <div className="comment-card">
            <Field
              component="textarea"
              className="new-comment"
              name="content"
              id="content"
            />
            <button
              type="submit"
              className="send-new-comment"
              disabled={!isValid || !values.content.trim()}
            >
              <FontAwesomeIcon icon={faPaperPlane} />
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CommentForm;
