import React from "react";

const OdsActivity3 = () => {
  return (
    <div className="ods-activities-container">
      <h2>ODS ACTIVIDAD 3:</h2>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645233/Volunteers/assets/ODS/2_bwktp1.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 2: Hambre cero: (Meta 2.4) </span> <br />
          En el caso de árboles frutales u otros árboles que producen alimentos,
          mantenerlos bien hidratados y fertilizados puede aumentar la
          producción de alimentos, contribuyendo a la seguridad alimentaria
          local.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645231/Volunteers/assets/ODS/3_tqqywb.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 3: Salud y bienestar: (Meta 3.9) </span> <br />
          Los árboles saludables mejoran la calidad del aire al absorber dióxido
          de carbono y liberar oxígeno, y pueden ayudar a reducir la
          contaminación del aire. Esto tiene un impacto positivo en la salud
          respiratoria y general de las comunidades.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645230/Volunteers/assets/ODS/6_mwuiqs.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 6: Agua limpia y saneamiento: (Meta 6.6) </span> <br />
          Mantener un árbol bien hidratado ayuda a preservar los recursos
          hídricos locales al fomentar un uso eficiente del agua y reducir la
          necesidad de riego excesivo. Además, los árboles pueden ayudar a
          mejorar la calidad del agua al reducir la escorrentía y la erosión del
          suelo.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/11_qyelnc.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 11: Ciudades y comunidades sostenibles: (Meta 11.7) </span>{" "}
          <br />
          Los árboles saludables mejoran la habitabilidad de las ciudades,
          proporcionando sombra, reduciendo el calor urbano y mejorando la
          estética y la calidad de vida en las comunidades urbanas y rurales.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/12_totmyp.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>
            ODS 12: Producción y consumo responsables: (Meta 12.2, 12.5){" "}
          </span>{" "}
          <br />
          Utilizar prácticas sostenibles de hidratación y fertilización puede
          minimizar el uso de recursos y reducir el desperdicio. Además, los
          árboles saludables pueden ser una fuente sostenible de productos
          naturales.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/13_tmtnkh.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 13: Acción por el clima: (Meta 13.1) </span> <br />
          Un árbol bien cuidado crece más rápidamente y absorbe más dióxido de
          carbono, lo que contribuye a la mitigación del cambio climático. Los
          árboles actúan como sumideros de carbono, ayudando a reducir la
          concentración de gases de efecto invernadero en la atmósfera.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/15_hd4qmx.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>
            ODS 15: Vida de ecosistemas terrestres: (Meta 15.1, 15.2){" "}
          </span>{" "}
          <br />
          Cuidar adecuadamente los árboles contribuye a la conservación de la
          biodiversidad y la salud de los ecosistemas terrestres. Los árboles
          proporcionan hábitat para numerosas especies de plantas y animales, y
          ayudan a mantener la integridad ecológica de los paisajes naturales.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/17_e8x3cm.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 17: Alianzas para lograr los objetivos: (Meta 17.17) </span>{" "}
          <br />
          El cuidado de los árboles puede ser una actividad comunitaria que
          fomente la colaboración entre diferentes actores, incluidos gobiernos,
          organizaciones no gubernamentales y ciudadanos. Estas alianzas pueden
          fortalecer los esfuerzos para alcanzar los objetivos de sostenibilidad
          y protección ambiental.
        </p>
      </div>
    </div>
  );
};

export default OdsActivity3;
