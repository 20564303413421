import { createContext, useContext, useReducer } from "react";

import {
  getTareasRequest,
  postTareasRequest,
  deleteTareaRequest,
  getTareaRequest,
  updateTareaRequest,
  getTareaRandomRequest,
  getTareasRandomRequest,
} from "../../api/tareas";
import { tareasReducer, initialState } from "../reducer/tareasReducer";
import { tareasActions } from "../actions/tareasActions";

export const tareasContext = createContext(initialState);

export const useTareas = () => {
  const context = useContext(tareasContext);
  return context;
};

export const TareasProvider = ({ children }) => {
  const [state, dispatch] = useReducer(tareasReducer, initialState);

  const loadTareas = async () => {
    dispatch({
      type: tareasActions.LOAD_TAREAS,
    });
    try {
      const res = await getTareasRequest();
      if (res.data) {
        dispatch({
          type: tareasActions.LOAD_TAREAS_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: tareasActions.LOAD_TAREAS_ERROR,
        payload: error.message,
      });
    }
  };

  const getTarea = async (id) => {
    dispatch({ type: tareasActions.GET_TAREA });
    try {
      const res = await getTareaRequest(id);
      dispatch({
        type: tareasActions.GET_TAREA_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: tareasActions.GET_TAREA_ERROR,
        payload: error.message,
      });
    }
  };

  const getTareaRand = async () => {
    dispatch({ type: tareasActions.GET_TAREA_RANDOM });
    try {
      const res = await getTareaRandomRequest();
      dispatch({
        type: tareasActions.GET_TAREA_RANDOM_SUCCESS,
        payload: res.data,
      });
      dispatch({
        type: tareasActions.GET_TAREA_SUCCESS,
        payload: res.data.tareaObtenida,
      });
    } catch (error) {
      dispatch({
        type: tareasActions.GET_TAREA_RANDOM_ERROR,
        payload: error.message,
      });
    }
  };

  const getTareasRandom = async (page, limit) => {
    dispatch({ type: tareasActions.GET_TAREAS_RANDOM });
    try {
      const res = await getTareasRandomRequest(page, limit);
      dispatch({
        type: tareasActions.GET_TAREAS_RANDOM_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } catch (error) {
      dispatch({
        type: tareasActions.GET_TAREAS_RANDOM_ERROR,
        payload: error.message,
      });
    }
  };

  const postTarea = async (tarea) => {
    dispatch({
      type: tareasActions.SAVE_TAREAS,
    });
    try {
      const res = await postTareasRequest(tarea);
      if (res.data) {
        dispatch({
          type: tareasActions.SAVE_TAREAS_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: tareasActions.SAVE_TAREAS_ERROR,
        payload: error.message,
      });
    }
  };

  const deleteTarea = async (tarea) => {
    dispatch({ type: tareasActions.DELETE_TAREA });
    try {
      const res = await deleteTareaRequest(tarea._id);
      if (res) {
        dispatch({
          type: tareasActions.DELETE_TAREA_SUCCESS,
          payload: tarea._id,
        });
      }
      return tarea.title;
    } catch (error) {
      dispatch({
        type: tareasActions.DELETE_TAREA_ERROR,
        payload: error.message,
      });
    }
  };

  const updateTarea = async (id, tarea) => {
    dispatch({ type: tareasActions.UPDATE_TAREA });
    try {
      const res = await updateTareaRequest(id, tarea);
      dispatch({
        type: tareasActions.UPDATE_TAREA_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } catch (error) {
      dispatch({
        type: tareasActions.UPDATE_TAREA_ERROR,
        payload: error.message,
      });
    }
  };

  const getTask18 = async (id) => {
    dispatch({ type: tareasActions.GET_TASK18 });
    try {
      const res = await getTareaRequest(id);

      const description = res.data.description;

      const resultQuest = description.split("####");
      const formattefResultQuests = resultQuest.map((resultQuest) => {
        return `${resultQuest.trim()}`;
      });
      const objetoTransformado = Object.keys(formattefResultQuests).reduce((acc, key) => {
        acc[`quest${parseInt(key) + 1}`] = formattefResultQuests[key];
        return acc;
      }, {});
      dispatch({
        type: tareasActions.GET_TASK18_SUCCESS,
        payload: objetoTransformado,
      });
    } catch (error) {
      dispatch({
        type: tareasActions.GET_TASK18_ERROR,
        payload: error.message,
      });
    }
  };

  return (
    <tareasContext.Provider
      value={{
        ...state,
        loadTareas,
        postTarea,
        deleteTarea,
        updateTarea,
        getTarea,
        getTareaRand,
        getTareasRandom,
        getTask18,
      }}
    >
      {children}
    </tareasContext.Provider>
  );
};
