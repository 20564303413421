import React from "react";

const OdsActivity8 = () => {
  return (
    <div className="ods-activities-container">
      <h2>ODS ACTIVIDAD 8:</h2>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645231/Volunteers/assets/ODS/4_xqio0d.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 4: Educación de calidad: (Meta 4.7) </span> <br />
          Fotografiar y describir un ecosistema contribuye a la educación
          ambiental, permitiendo que las personas, especialmente los
          estudiantes, adquieran conocimientos sobre la biodiversidad y la
          importancia de los ecosistemas para el desarrollo sostenible. Este
          tipo de actividades educativas promueve la conciencia ambiental y la
          valoración de la naturaleza.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/11_qyelnc.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 11: Ciudades y comunidades sostenibles: (Meta 11.4) </span>{" "}
          <br />
          Al documentar y describir los ecosistemas locales, se contribuye a la
          protección y salvaguardia del patrimonio natural.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/13_tmtnkh.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 13: Acción por el clima: (Meta 13.3) </span> <br />
          Entender y documentar los ecosistemas locales puede proporcionar datos
          importantes para las estrategias de mitigación y adaptación al cambio
          climático, mostrando cómo estos ecosistemas están siendo afectados y
          cómo pueden ser protegidos.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/14_mbd8kd.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 14: Vida submarina: (Meta 14.2) </span> <br />
          Si el ecosistema descrito incluye cuerpos de agua, esto ayuda a
          aumentar la conciencia sobre la importancia de los ecosistemas
          acuáticos y la necesidad de protegerlos para mantener la biodiversidad
          marina.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/15_hd4qmx.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>
            ODS 15: Vida de ecosistemas terrestres: (Meta 15.1, 15.9){" "}
          </span>{" "}
          <br />
          Fotografiar y describir un ecosistema terrestre contribuye a la
          conservación de la biodiversidad y la gestión sostenible de los
          bosques y otros ecosistemas naturales. Aumenta la conciencia sobre la
          importancia de proteger y restaurar estos espacios.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/17_e8x3cm.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 17: Alianzas para lograr los objetivos: (Meta 17.16) </span>{" "}
          <br />
          Documentar y compartir información sobre los ecosistemas puede
          fomentar la colaboración entre científicos, gobiernos, organizaciones
          y comunidades locales, fortaleciendo las alianzas necesarias para la
          conservación y el desarrollo sostenible.
        </p>
      </div>
    </div>
  );
};

export default OdsActivity8;
