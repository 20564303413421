import React, { useEffect, useState } from "react";

const DateComment = ({ date, className}) => {
  const createdAtDate = new Date(date);
  const currentTime = Date.now();
  const elapsedTime = currentTime - createdAtDate.getTime();

  const seconds = Math.floor(elapsedTime / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const week = Math.floor(days / 7);
  const years = Math.floor(days / 365);

  const [time, setTime] = useState("");

  useEffect(() => {
    if (years > 0) {
      setTime(`${years} año${years !== 1 ? "s." : "."}`);
    } else if (week > 0) {
      setTime(`${week} semana${week !== 1 ? "s." : "."}`);
    } else if (days > 0) {
      setTime(`${days} dia${days !== 1 ? "s." : "."}`);
    } else if (hours > 0) {
      setTime(`${hours} hora${hours !== 1 ? "s." : "."}`);
    } else if (minutes > 0) {
      setTime(`${minutes} minuto${minutes !== 1 ? "s." : "."}`);
    } else {
      setTime(`${seconds} segundo${seconds !== 1 ? "s." : "."}`);
    }
  }, []);
  return <span className={className}>{time}</span>;
};

export default DateComment;
