import axios from "axios";
import { apiURL } from "./apiConfig";

export const getMessagesRequest = async () => {
  try {
    return await axios.get(`${apiURL}/api/messages`);
  } catch (error) {
    throw error;
  }
};

export const getMessageRequest = async (id) => {
  try {
    return await axios.get(`${apiURL}/api/messages/${id}`);
  } catch (error) {
    throw error;
  }
};

export const createMessageRequest = async (message) => {
  const token = localStorage.getItem("token");
  try {
    return await axios.post(`${apiURL}/api/messages`, message, {
      headers: {
        Authorization: token,
      },
    });
  } catch (error) {
    console.log(error)
    throw error.response;
  }
};

export const updateMessageRequest = async (id, message) => {
  const token = localStorage.getItem("token");
  try {
    return await axios.put(`${apiURL}/api/messages/${id}`, message, {
      headers: {
        Authorization: token,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const deleteMessageRequest = async (id) => {
  const token = localStorage.getItem("token");
  try {
    return await axios.delete(`${apiURL}/api/messages/${id}`, {
      headers: {
        Authorization: token,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const messagesToMyRequest = async (id) =>{
  const token = localStorage.getItem("token");
  try {
    return await axios.get(`${apiURL}/api/messages/to-my/${id}`,{
      headers:{
        Authorization: token,
      }
    })
  } catch (error) {
    console.log(error);
  }
}