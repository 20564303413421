import { createContext, useContext, useEffect, useReducer } from "react";

import {
  getActivitiesRequest,
  getActivityRequest,
  postActivityRequest,
  updateActivityRequest,
  deleteActivityRequest,
} from "../../api/activities";

import { activitiesReducer, initialState } from "../reducer/activitiesReducer";

import { activitiesActions } from "../actions/activitiesActions";

export const activitiesContext = createContext(initialState);

export const useActivities = () => {
  const context = useContext(activitiesContext);
  return context;
};

export const ActivitiesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(activitiesReducer, initialState);

  const loadActivities = async () => {
    dispatch({
      type: activitiesActions.LOAD_ACTIVITIES,
    });
    try {
      const res = await getActivitiesRequest();
      if (res.data) {
        dispatch({
          type: activitiesActions.LOAD_ACTIVITIES_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: activitiesActions.LOAD_ACTIVITIES_ERROR,
        payload: error.message,
      });
    }
  };

  useEffect(() => {
    loadActivities();
  }, []);

  const getActivity = async (id) => {
    dispatch({
      type: activitiesActions.GET_ACTIVITIES,
    });
    try {
      const res = await getActivityRequest(id);
      dispatch({
        type: activitiesActions.GET_ACTIVITIES_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: activitiesActions.GET_ACTIVITIES_ERROR,
        payload: error.response.data.error,
      });
    }
  };

  const postActivity = async (activity) => {
    dispatch({ type: activitiesActions.SAVE_ACTIVITIES });
    try {
      const res = await postActivityRequest(activity);
      if (res.data) {
        dispatch({
          type: activitiesActions.SAVE_ACTIVITIES_SUCCESS,
          payload: res.data,
        });
      }
      return res.data;
    } catch (error) {
      dispatch({
        type: activitiesActions.SAVE_ACTIVITIES_ERROR,
        payload: error,
      });
      throw error;
    }
  };

  const deleteActivity = async (activity) => {
    dispatch({ type: activitiesActions.DELETE_ACTIVITIES });
    try {
      const res = await deleteActivityRequest(activity._id);
      if (res) {
        dispatch({
          type: activitiesActions.DELETE_ACTIVITIES_SUCCESS,
          payload: activity._id,
        });
      }
      return activity.title;
    } catch (error) {
      dispatch({
        type: activitiesActions.DELETE_ACTIVITIES_ERROR,
        payload: error.message,
      });
    }
  };
  const updateActivity = async (id, activity) => {
    dispatch({ type: activitiesActions.UPDATE_ACTIVITIES });
    try {
      const res = await updateActivityRequest(id, activity);
      dispatch({
        type: activitiesActions.UPDATE_ACTIVITIES_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } catch (error) {
      dispatch({
        type: activitiesActions.UPDATE_ACTIVITIES_ERROR,
        payload: error.message,
      });
    }
  };

  return (
    <activitiesContext.Provider
      value={{
        ...state,
        loadActivities,
        postActivity,
        deleteActivity,
        updateActivity,
        getActivity,
      }}
    >
      {children}
    </activitiesContext.Provider>
  );
};
