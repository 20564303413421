import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTareas } from "../context/providers/tareasContext";


const Exam = ({ tarea, userTarea }) => {
  const { getTarea } = useTareas();

  const navigate = useNavigate();

  const [quest, setQuest] = useState([]);

  const questions = () => {
    const question = tarea?.description.split("?");
    const formattefQuestions = question.map((question) => {
      return `${question.trim()}`;
    });
    setQuest(formattefQuestions);
  };

  useEffect(() => {
    questions();
  }, []);

  return (
    <div className="card-content activity-id" id="activity-id">
      <img
        className="imagen-fondo-tarea"
        src="https://cdn.pixabay.com/photo/2016/03/27/18/49/man-1283576_1280.jpg"
        alt="Imagen de tarea"
      />
      <div className="activity-text">
        <hr className="hr-activities"></hr>
        <div className="title-activity-card" id={`low-activity${tarea?.number}`}>
          <span className="number-activity title-activity-account">
            {tarea?.number}. {tarea?.title}
          </span>
          <div id={`activity${tarea?.number}`} className="text-activity">
            {quest.map((q, index) => (
              <div key={index}>
                {index === 5 ? <p>{q}</p> : <p>{`${q}?`}</p>}
              </div>
            ))}
          </div>

          <div className="card-imgpng-free-container">
            <div className="video-activities-container">
              <video
                className="video-activity"
                src="https://res.cloudinary.com/dd8a6qc45/video/upload/v1708440014/Volunteers/Video/night_sky_540p_iduip2.mp4"
                controls
              />
            </div>
            <div className="tarea-user-container">
              <Link
                onClick={async () => {
                  await getTarea(userTarea?._id);
                  navigate(`/exam/${userTarea?._id}`);
                }}
                id="image-animation"
              >
                Responder...
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Exam;
