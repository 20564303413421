export const tareasActions = {
  LOAD_TAREAS: "LOAD_TAREAS",
  LOAD_TAREAS_SUCCESS: "LOAD_TAREAS_SUCCESS",
  LOAD_TAREAS_ERROR: "LOAD_TAREAS_ERROR",

  SAVE_TAREAS: "SAVE_TAREAS",
  SAVE_TAREAS_SUCCESS: "SAVE_TAREAS_SUCCESS",
  SAVE_TAREAS_ERROR: "SAVE_TAREAS_ERROR",

  UPDATE_TAREA: "UPDATE_TAREA",
  UPDATE_TAREA_SUCCESS: "UPDATE_TAREA_SUCCESS",
  UPDATE_TAREA_ERROR: "UPDATE_TAREA_ERROR",

  DELETE_TAREA: "DELETE_TAREA",
  DELETE_TAREA_SUCCESS: "DELETE_TAREA_SUCCESS",
  DELETE_TAREA_ERROR: "DELETE_TAREA_ERROR",

  GET_TAREA: "GET_TAREA",
  GET_TAREA_SUCCESS: "GET_TAREA_SUCCESS",
  GET_TAREA_ERROR: "GET_TAREA_ERROR",

  GET_TAREA_RANDOM: "GET_TAREA_RANDOM",
  GET_TAREA_RANDOM_SUCCESS: "GET_TAREA_RANDOM_SUCCESS",
  GET_TAREA_RANDOM_ERROR: "GET_TAREA_RANDOM_ERROR",

  GET_TAREAS_RANDOM: "GET_TAREAS_RANDOM",
  GET_TAREAS_RANDOM_SUCCESS: "GET_TAREAS_RANDOM_SUCCESS",
  GET_TAREAS_RANDOM_ERROR: "GET_TAREAS_RANDOM_ERROR",

  GET_TASK18: "GET_TASK18",
  GET_TASK18_SUCCESS: "GET_TASK18_SUCCESS",
  GET_TASK18_ERROR: "GET_TASK18_ERROR",
};
