import { createContext, useContext, useEffect, useReducer } from "react";

import {
  getCommentsAllRequest,
  getCommentsTareaRequest,
  getCommentRequest,
  createCommentRequest,
  deleteCommentRequest,
  updateCommentRequest,
} from "../../api/comments";

import { commentsReducer, initialState } from "../reducer/commentReducer";
import { commentsActions } from "../actions/commentsActions";

export const commentContext = createContext(initialState);

export const useComments = () => {
  const context = useContext(commentContext);
  return context;
};

export const CommentsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(commentsReducer, initialState);

  const loadComments = async () => {
    dispatch({
      type: commentsActions.LOAD_COMMENTS,
    });
    try {
      const res = await getCommentsAllRequest();
      if (res.data) {
        dispatch({
          type: commentsActions.LOAD_COMMENTS_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: commentsActions.LOAD_COMMENTS_ERROR,
        payload: error.message,
      });
    }
  };

  useEffect(() => {
    loadComments();
  }, []);

  const getCommentsTarea = async (idTarea) => {
    dispatch({
      type: commentsActions.GET_COMMENTS_TAREA,
    });
    try {
      if (!idTarea) {
        return "No se envio ningun id de Tarea";
      }
      const res = await getCommentsTareaRequest(idTarea);
      if (res.data) {
        dispatch({
          type: commentsActions.GET_COMMENTS_TAREA_SUCCESS,
          payload: res.data,
        });
      } else {
        console.log("La tarea no tiene comentarios");
      }
      return res.data;
    } catch (error) {
      dispatch({
        type: commentsActions.GET_COMMENTS_TAREA_ERROR,
        payload: error.message,
      });
    }
  };

  const cleanNewComments = async () => {
    dispatch({ type: commentsActions.CLEAN_COMMENT });
    try {
      dispatch({
        type: commentsActions.CLEAN_COMMENT_SUCCESS,
        payload: [],
      });
    } catch (error) {
      dispatch({
        type: commentsActions.CLEAN_COMMENT_ERROR,
        payload: error.message,
      });
    }
  };

  const getComment = async (idComment) => {
    dispatch({ type: commentsActions.GET_COMMENT });
    try {
      const res = await getCommentRequest(idComment);
      console.log(res.data);
      dispatch({
        type: commentsActions.GET_COMMENT_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: commentsActions.GET_COMMENT_ERROR,
        payload: error.message,
      });
    }
  };

  const createComment = async (comment) => {
    dispatch({
      type: commentsActions.SAVE_COMMENT,
    });
    try {
      const res = await createCommentRequest(comment);
      if (res.data) {
        dispatch({
          type: commentsActions.SAVE_COMMENT_SUCCESS,
          payload: {
            comments: res.data,
            newComment: res.data,
          },
        });
        return res.data;
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: commentsActions.SAVE_COMMENT_ERROR,
        payload: error,
      });
    }
  };

  const deleteComment = async (idComment) => {
    dispatch({ type: commentsActions.DELETE_COMMENT });
    try {
      const res = await deleteCommentRequest(idComment);
      if (res) {
        dispatch({
          type: commentsActions.DELETE_COMMENT_SUCCESS,
          payload: idComment,
        });
        return idComment;
      }
    } catch (error) {
      dispatch({
        type: commentsActions.DELETE_COMMENT_ERROR,
        payload: error.message,
      });
    }
  };

  const updateComment = async (idComment, comment) => {
    dispatch({ type: commentsActions.UPDATE_COMMENT });
    try {
      const res = await updateCommentRequest(idComment, comment);
      dispatch({
        type: commentsActions.UPDATE_COMMENT_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } catch (error) {
      dispatch({
        type: commentsActions.UPDATE_COMMENT_ERROR,
        payload: error.message,
      });
    }
  };

  return (
    <commentContext.Provider
      value={{
        ...state,
        loadComments,
        createComment,
        deleteComment,
        updateComment,
        getComment,
        getCommentsTarea,
        cleanNewComments,
      }}
    >
      {children}
    </commentContext.Provider>
  );
};
