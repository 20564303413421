import React, { useEffect, useRef, useState } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";

import "../styles/chat.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleXmark,
  faPaperPlane,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { useMessages } from "../context/providers/messagesContext";
import { useUser } from "../context/providers/userContext";
import { Spinner } from "./ui/Spinner";
import DateComment from "./ui/DateComment";
import { io } from "socket.io-client";

const Chat = ({ idUser, close, socket }) => {
// const Chat = ({ idUser, close }) => {

  const messageContainerRef = useRef(null);
  const {
    createMessage,
    messagesUser,
    messagesUserToUser,
    removeMessage,
    isLoadingChatHistory,
  } = useMessages();

  const [messages, setMessages] = useState(messagesUser);

  const { user, userIdChat, getUserId, isLoading } = useUser();

  var message = {
    text: "",
  };

  console.log(socket)
  const handleSubmit = async (values, { resetForm }) => {
    if (values.text) {
      values.sender = userIdChat._id;
      values.token = user.token;
      resetForm();
      try {
        const resMessages = socket.emit("createMessage", values);
        socket.on('nuevoMensaje', async (data) =>{
          console.log(data)
          // await messagesUserToUser(userIdChat._id);
        })
        // if (resMessages) {
        //   setMessages(messagesUser);
        // }
        // const res = await createMessage(values);
        // const resMessages = await messagesUserToUser(userIdChat._id);
        // if (resMessages) {
        //   setMessages(messagesUser);
        // }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleKeyPress = (e, handleSubmit) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    }
  };

  const handleDelete = async (id) => {
    try {
      await removeMessage(id);
      await messagesUserToUser(userIdChat._id);
    } catch (error) {
      console.log(error);
    }
  };
  const hiddenChat = async () => {
    close(false);
  };

  useEffect(() => {
    messageContainerRef.current.scrollTop =
      messageContainerRef.current.scrollHeight;
  }, [messagesUser]);


  return (
    <section className="section-chat-into">
      {isLoadingChatHistory ? (
        Spinner
      ) : (
        <>
          <article className="header-chat-into-container">
            <div className="img-chat-into-container-relative">
              <div className="img-chat-into-container">
                <img
                  src={
                    userIdChat?.image?.url ||
                    "https://res.cloudinary.com/dd8a6qc45/image/upload/v1690634531/Volunteers/perfilImage/account_e6qpmh.svg"
                  }
                  alt="Foto de perfil"
                />
              </div>
            </div>
            <div className="name-chat-container">
              <h2>{userIdChat?.name}</h2>
              <h3>on-line</h3>
            </div>
            <div className="buttom-close-chat" onClick={hiddenChat}>
              <FontAwesomeIcon icon={faCircleXmark} />
            </div>
          </article>
          <article className="chat-content">
            <div className="messages-card" ref={messageContainerRef}>
              {messagesUser.map((messageUnit) => (
                <div
                  key={messageUnit._id}
                  className={`messages-unit ${
                    messageUnit.sender === userIdChat?._id
                      ? "send-message"
                      : "received-message"
                  }`}
                >
                  <div>
                    {messageUnit.author === user._id && (
                      <button
                        className="button-delete-message"
                        onClick={(e) => handleDelete(messageUnit._id)}
                      >
                        <FontAwesomeIcon icon={faTrashCan} />
                      </button>
                    )}
                    <p>{messageUnit.text}</p>
                  </div>
                  <DateComment
                    date={messageUnit.createdAt}
                    className={"message-unit-date"}
                  />
                </div>
              ))}
            </div>
          </article>
          <label htmlFor="text">Nuevo mensaje</label>
          <Formik
            className="form-chat"
            initialValues={message}
            validationSchema={Yup.object({
              text: Yup.string(),
            })}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ values, isValid, handleSubmit }) => (
              <Form className="form-create-message">
                <Field
                  className="field-form-create-message"
                  component="textarea"
                  name="text"
                  id="text"
                  onKeyPress={(e) => handleKeyPress(e, handleSubmit)}
                />
                <button
                  className="button-send-message"
                  type="submit"
                  disabled={!values.text || !isValid}
                  onClick={handleSubmit}
                >
                  <FontAwesomeIcon icon={faPaperPlane} />
                </button>
              </Form>
            )}
          </Formik>
        </>
      )}
    </section>
  );
};

export default Chat;
