import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { useComments } from "../../context/providers/commentsContext";
import { useUser } from "../../context/providers/userContext";
import { Link } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useTareas } from "../../context/providers/tareasContext";
import DateComment from "./DateComment";

const OldComments = ({ tarea, comments, onDelete }) => {
  const { deleteComment, errorMessage, newComment } = useComments();
  const { isAdmin, isLoading } = useUser();

  const [newCommentState, setNewCommentState] = useState({});

  // const handleDelete = async (e, id) => {
  //   e.preventDefault();
  //   try {
  //     if (isAdmin) {
  //       const result = await deleteComment(id);
  //       if (result) {
  //         toast.success(`Se ha eliminado el comentario con exito`, {
  //           position: "bottom-right",
  //         });
  //       }
  //     }
  //   } catch (error) {
  //     toast.error(errorMessage, {
  //       position: "bottom-right",
  //     });
  //   }
  // };

  // const start = async ()=> {

  //   setNewCommentState(newComment);
  // }
  // useEffect(() => {
  //   start();
  //   }, [newComment]);

  return (
    <div className="old-comments-card">
      {/* {!isLoadingComment ? ( */}
        <div>
          {comments && comments.map((comment) => (
            <div key={comment._id} className="old-comment-single">
              <div className="old-comment">
                <div className="img-perfil-comment">
                  <Link to={`/profile/${comment.user?._id}`}>
                    <img
                      src={
                        comment.user?.image?.url ||
                        "https://res.cloudinary.com/dd8a6qc45/image/upload/v1690634531/Volunteers/perfilImage/account_e6qpmh.svg"
                      }
                      alt=""
                    />
                  </Link>
                </div>
                <Link to={`/profile/${comment.user?._id}`}>
                  <span>{comment.user?.name}</span>
                </Link>
              </div>
              <div className="old-comment-card">
                <p className="comment">{comment.content}</p>
                <DateComment date={comment.createdAt} className={"date-comments-span"}/>
                {isAdmin ? (
                  <button
                    className="button-delete-comment"
                    onClick={(e) => onDelete(e, comment._id)}
                  >
                    <FontAwesomeIcon icon={faTrashCan} />
                  </button>
                ) : (
                  <></>
                )}
              </div>
            </div>
          ))}
        </div>
      {/* ) : (
        <>
          <p>Cargando...</p>
        </>
      )} */}
    </div>
  );
};

export default OldComments;
