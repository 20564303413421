import React, { useEffect, useState } from "react";

import {
  Document,
  Text,
  Page,
  StyleSheet,
  Image,
  View,
} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    padding: "40px 40px",
    border: "2px solid black",
    alignItems: "center",
  },
  title: {
    fontSize: "32px",
    margin: "12px",
    textAlign: "center",
  },
  img: {
    margin: "16px 0",
    width: "200px",
  },
  text: {
    textAlign: "justify",
    padding: "8px 12px",
    fontSize: "11px",
  },
  footer:{
    justifyContent: "space-between",
    flexDirection: "row",
    padding: "0px 100px",
  },
  alignLeft: {
    width: "100%",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    paddingTop: "24px",
  },
  textAt: {
    padding: "28px 12px",
    marginBottom: "12px",
    fontSize: "11px",
    textAlign: "left",
  },

  textLeft: {
    padding: "0px 12px",
    fontSize: "9px",
    marginTop: "0px",
    textAlign: "left",
  },
  imgQR:{
    width: "100px",
    marginTop: "40px",
  },
});
const PDF = ({ QR }) => {
  const [imageSrc, setImageSrc] = useState("");

  useEffect(() => {
    const convertSvgToPng = async () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const svgBlob = new Blob([QR], { type: "image/svg+xml" });
      const url = URL.createObjectURL(svgBlob);

      const img = document.createElement("img");
      img.onload = function () {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);

        const imgUrl = canvas.toDataURL("image/png");

        URL.revokeObjectURL(url);

        setImageSrc(imgUrl);
      };

      img.onerror = function () {
        console.error("Error al cargar la imagen");
      };

      img.src = url;
    };

    convertSvgToPng();
  }, []);

  return (
    <Document>
      <Page style={styles.page}>
        <Text style={styles.title}>VOLUNTEERS</Text>
        <Image
          style={styles.img}
          src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1710177344/Volunteers/assets/TRIANGULO-NEGRO-VOLUNTEERS-1080p_qllgq2.png"
        />
        <Text style={styles.text}>
          ¡Bienvenido a nuestra comunidad comprometida con el cuidado del medio
          ambiente! Estamos encantados de darte la bienvenida como nuevo miembro
          de esta noble causa.
        </Text>

        <Text style={styles.text}>
          Queremos agradecerte por unirte a nosotros en nuestra misión de
          proteger y mejorar nuestro planeta. Tu participación es fundamental
          para hacer una diferencia significativa en la preservación de nuestro
          entorno natural y la promoción de un futuro sostenible para las
          generaciones venideras.
        </Text>

        <Text style={styles.text}>
          Para comenzar, te hemos proporcionado un código QR exclusivo que te
          llevará directamente a nuestra página web, donde podrás registrarte y
          comenzar a participar en nuestras actividades. Recuerda que este
          código es necesario para acceder a nuestro sitio y convertirte en un
          miembro activo de nuestra comunidad.
        </Text>

        <Text style={styles.text}>
          Queremos inspirarte a involucrarte plenamente en todas las actividades
          que ofrecemos en nuestra plataforma. Cada pequeña acción cuenta, y
          juntos podemos marcar la diferencia. ¡Tu contribución es invaluable
          para alcanzar nuestros objetivos y crear un impacto positivo en el
          medio ambiente!
        </Text>

        <Text style={styles.text}>
          No dudes en contactarnos si tienes alguna pregunta o necesitas ayuda
          durante tu viaje con nosotros. Estamos aquí para apoyarte en cada paso
          del camino.
        </Text>

        <Text style={styles.text}>
          Una vez más, te damos las gracias por unirte a nosotros y esperamos
          con ansias trabajar juntos para construir un futuro más verde y
          próspero.
        </Text>

        <Text style={styles.text}>¡Bienvenido a nuestra comunidad!</Text>
        <View style={styles.footer}>
          <View style={styles.alignLeft}>
            <Text style={styles.textAt}>Atentamente,</Text>

            <Text style={styles.textLeft}>Equipo de Soporte</Text>
            <Text style={styles.textLeft}>Volunteers</Text>
          </View>
          <View>
            <Image style={styles.imgQR} src={imageSrc} />
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PDF;
