import React from "react";

const OdsActivity18 = () => {
  return (
    <div className="ods-activities-container">
      <h2>ODS ACTIVIDAD 18:</h2>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645226/Volunteers/assets/ODS/10_vydjaj.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>
            ODS 10: Reducción de las Desigualdades: (Meta 10.2, 10.3, 10.4){" "}
          </span>{" "}
          <br />
          La capacitación y sensibilización sobre el cambio climático pueden
          empoderar a las comunidades más vulnerables y marginadas, que son
          desproporcionadamente afectadas por los impactos del cambio climático.
          Al proporcionarles el conocimiento y las herramientas necesarias para
          adaptarse y mitigar estos efectos, se promueve su inclusión social y
          económica. Así mismo la educación sobre el cambio climático puede
          ayudar a generar conciencia sobre cómo las políticas y prácticas
          pueden afectar de manera desigual a diferentes grupos. Al sensibilizar
          a la sociedad y a los responsables de la toma de decisiones, se pueden
          promover políticas más justas y equitativas que aborden las
          necesidades específicas de los más vulnerables. Finalmente, capacitar
          a las personas sobre el cambio climático puede influir en la adopción
          de políticas fiscales y de protección social que apoyen a las
          comunidades afectadas por el cambio climático, ayudando a reducir las
          desigualdades.
        </p>
      </div>
    </div>
  );
};

export default OdsActivity18;
