import React from "react";

const OdsActivity7 = () => {
  return (
    <div className="ods-activities-container">
      <h2>ODS ACTIVIDAD 7:</h2>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645231/Volunteers/assets/ODS/3_tqqywb.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 3: Salud y Bienestar: (Meta 3.4) </span> <br />
          La meditación en la naturaleza es una práctica que puede reducir el
          estrés, la ansiedad y la depresión, mejorando significativamente la
          salud mental y el bienestar general. Promover esta práctica puede
          contribuir a la prevención de enfermedades no transmisibles
          relacionadas con el estrés y fomentar una vida más saludable.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645231/Volunteers/assets/ODS/4_xqio0d.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 4: Educación de Calidad: (Meta 4.7) </span> <br />
          Investigar sobre la meditación y ponerla en práctica en la naturaleza
          puede ser parte de la educación para el desarrollo sostenible. Al
          enseñar a los estudiantes sobre la importancia del bienestar mental y
          el impacto positivo de la naturaleza en la salud, se les proporciona
          herramientas prácticas para vivir de manera más sostenible y en
          armonía con el medio ambiente. Esto promueve una educación integral
          que valora tanto el desarrollo personal como el ambiental.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645231/Volunteers/assets/ODS/5_emwyux.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 5: Igualdad de Género: (Meta 5.B) </span> <br />
          La meditación en la naturaleza puede servir como una práctica
          complementaria para el bienestar integral de las mujeres, apoyando su
          empoderamiento y liderazgo a través de una mayor claridad mental y
          bienestar emocional.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/11_qyelnc.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 11: Ciudades y Comunidades Sostenibles: (Meta 11.7) </span>{" "}
          <br />
          Practicar la meditación en la naturaleza subraya la importancia de
          tener acceso a espacios verdes y naturales dentro de las ciudades y
          comunidades. Promover la meditación al aire libre puede aumentar la
          demanda de más espacios verdes accesibles y bien cuidados,
          contribuyendo a la creación de comunidades más sostenibles y
          habitables.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/12_totmyp.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 12: Producción y Consumo Responsables: (Meta 12.8) </span>{" "}
          <br />
          La práctica de la meditación en la naturaleza puede aumentar la
          conciencia sobre la importancia de vivir en armonía con el medio
          ambiente. Esta conciencia puede traducirse en un comportamiento más
          responsable en términos de consumo y producción, promoviendo estilos
          de vida sostenibles.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/13_tmtnkh.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 13: Acción por el Clima: (Meta 13.3) </span> <br />
          Practicar la meditación en la naturaleza puede aumentar la conexión y
          el compromiso de las personas con el medio ambiente, fomentando una
          mayor sensibilidad hacia los problemas del cambio climático. Este
          mayor nivel de conciencia puede impulsar acciones individuales y
          colectivas para mitigar los efectos del cambio climático.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/15_hd4qmx.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 15: Vida de Ecosistemas Terrestres: (Meta 15.1) </span>{" "}
          <br />
          Las personas que practican la meditación al aire libre pueden
          desarrollar un sentido más profundo de conexión y responsabilidad
          hacia la preservación de la biodiversidad y los ecosistemas.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/16_pny0dl.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>
            ODS 16: Paz, Justicia e Instituciones Sólidas: (Meta 16.1, 16.7){" "}
          </span>{" "}
          <br />
          La meditación en la naturaleza puede ayudar a reducir el estrés y la
          ansiedad, lo que a su vez puede disminuir la violencia interpersonal y
          promover una mayor paz y armonía en las comunidades, fomentando una
          mentalidad más inclusiva y empática, facilitando la adopción de
          decisiones más justas y equitativas que consideren las necesidades de
          todos los miembros de la comunidad.
        </p>
      </div>
    </div>
  );
};

export default OdsActivity7;
