import { createContext, useContext, useEffect, useReducer } from "react";

import { messagesReducer, initialState } from "../reducer/messagesReducer";

import { messagesActions } from "../actions/messagesActions";
import {
  createMessageRequest,
  deleteMessageRequest,
  getMessageRequest,
  getMessagesRequest,
  messagesToMyRequest,
} from "../../api/messages";
import { updateCommentRequest } from "../../api/comments";

export const messagesContext = createContext(initialState);

export const useMessages = () => {
  const context = useContext(messagesContext);
  return context;
};

export const MessagesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(messagesReducer, initialState);

  const loadMessages = async () => {
    dispatch({
      type: messagesActions.LOAD_COMMENTS,
    });
    try {
      const res = await getMessagesRequest();
      if (res.data) {
        dispatch({
          type: messagesActions.LOAD_COMMENTS_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: messagesActions.LOAD_COMMENTS_ERROR,
        payload: error,
      });
    }
  };

  const getMessage = async (id) => {
    dispatch({
      type: messagesActions.GET_MESSAGE,
    });
    try {
      const res = await getMessageRequest(id);
      if (res.data) {
        dispatch({
          type: messagesActions.GET_MESSAGE_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: messagesActions.GET_MESSAGE_ERROR,
        payload: error,
      });
    }
  };
  const createMessage = async (message) => {
    dispatch({ type: messagesActions.SAVE_MESSAGE });
    try {
      const res = await createMessageRequest(message);

      if (res.data) {
        dispatch({
          type: messagesActions.SAVE_MESSAGE_SUCCESS,
          payload: {
            messages: res.data,
            newMessage: res.data,
          },
        });
        return res.data;
      }
    } catch (error) {
      dispatch({
        type: messagesActions.SAVE_MESSAGE_ERROR,
        payload: error,
      });
    }
  };

  const updateMessage = async (id, message) => {
    dispatch({ type: messagesActions.UPDATE_MESSAGE });
    try {
      const res = await updateCommentRequest(id, message);
      if (res.data) {
        dispatch({
          type: messagesActions.UPDATE_MESSAGE_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: messagesActions.UPDATE_MESSAGE_ERROR,
        payload: error,
      });
    }
  };
  const removeMessage = async (id) => {
    dispatch({ type: messagesActions.REMOVE_MESSAGE });
    try {
      const res = await deleteMessageRequest(id);
      if (res.data) {
        dispatch({
          type: messagesActions.REMOVE_MESSAGE_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: messagesActions.REMOVE_MESSAGE_ERROR,
        payload: error,
      });
    }
  };

  const messagesUserToUser = async (id) => {
    dispatch({ type: messagesActions.LOAD_MESSAGES_USER });
    try {
      const res = await messagesToMyRequest(id);
      if (res.data) {
        dispatch({
          type: messagesActions.LOAD_MESSAGES_USER_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: messagesActions.LOAD_MESSAGES_USER_ERROR,
        payload: error,
      });
    }
  };

  const viewChatController = async (bool) => {
    dispatch({ type: messagesActions.VIEW_CHAT });
    try {
      dispatch({
        type: messagesActions.VIEW_CHAT_SUCCESS,
        payload: bool,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: messagesActions.VIEW_CHAT_ERROR,
        payload: error,
      });
    }
  };

  return (
    <messagesContext.Provider
      value={{
        ...state,
        loadMessages,
        getMessage,
        createMessage,
        updateMessage,
        removeMessage,
        messagesUserToUser,
        viewChatController,
      }}
    >
      {children}
    </messagesContext.Provider>
  );
};
