import React, { useEffect, useState } from "react";
import { useUser } from "../../context/providers/userContext";
import { Link, useParams } from "react-router-dom";
import { Spinner } from "../../components/ui/Spinner";
import Tareas from "../../components/ui/AdminPanel/Tareas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCameraRetro } from "@fortawesome/free-solid-svg-icons";
import { useMessages } from "../../context/providers/messagesContext";
import SendMessagesPerfil from "../../components/SendMessagePerfil";

const AdminUserPage = () => {
  const { getUserId, userId, isLoading } = useUser();
  const {messagesUserToUser} = useMessages();

  const params = useParams();

  const [showChat, setShowChat] = useState(false);


  const handleChat = async () => {
    if (showChat) {
      setShowChat(false);
    } else {
      await messagesUserToUser(params.id);
      setShowChat(true);
    }
  };

  useEffect(() => {
    getUserId(params.id);
  }, []);

  if (isLoading) {
    return Spinner;
  }

  return (
    <main className="main-amdin-page">
      <section className="head-perfil">
        <h1>{userId?.name}</h1>
        <div className="img-fondo">
          <img
            src="https://images.pexels.com/photos/1324803/pexels-photo-1324803.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            alt="Imagen de fondo"
          />
        </div>
        <div className="img-perfil-card">
          <div className="img-perfil">
            <img
              src={
                userId?.image && userId?.image.url
                  ? userId?.image.url
                  : "https://res.cloudinary.com/dd8a6qc45/image/upload/v1690634531/Volunteers/perfilImage/account_e6qpmh.svg"
              }
              alt="Foto de perfil"
            />
            <div className="update-perfil">
              <Link to={`/update-perfil/${userId?._id}`}>
                <FontAwesomeIcon icon={faCameraRetro} />
              </Link>
            </div>
            {/* <div className="send-message-all-container">
                  <button
                    className="button-send-message-user send-message-container"
                    onClick={() => {
                      handleChat();
                    }}
                  >
                    Enviar Mensaje
                  </button>
                  {showChat && (
                    <SendMessagesPerfil userChat={userId} />
                    )}
                </div> */}
          </div>
        </div>
      </section>
      <section className="section-admin-page">
        <Tareas user={userId} />
      </section>
    </main>
  );
};

export default AdminUserPage;
