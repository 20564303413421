import React from "react";
import OldComments from "./ui/OldComments";

const ActivityProfiles = ({ tarea }) => {
  return (
    <>
      <img
        className="imagen-fondo-tarea"
        src={
          tarea.image && tarea.image.url
            ? tarea.image.url
            : "https://res.cloudinary.com/dd8a6qc45/image/upload/v1690574586/mundo_ldar8i.png"
        }
        alt="Imagen de tarea"
      />

      <div className="card-content-other-profile activity-id" id="activity-id">
        <div className="activity-text">
          <hr className="hr-activities"></hr>
          <div
            className="title-activity-card"
            id={`low-activity${tarea.number}`}
          >
            <span className="number-activity center-number-activity">
              {tarea.number}
            </span>
            <p id={`activity${tarea.number}`} className="text-activity">
              {tarea.description}
            </p>
          </div>
          <div className="img-profile-container">
            <img
              className="img-profile"
              src={
                tarea && tarea.image
                  ? tarea.image.url
                  : "https://res.cloudinary.com/dd8a6qc45/image/upload/v1690574586/mundo_ldar8i.png"
              }
              alt="Imagen de tarea"
            />
          </div>
          {tarea.comment ? (
            <div className="comments-user-container">
              <div className="comments-profile-container">
                <OldComments comments={tarea.comment} />
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default ActivityProfiles;
