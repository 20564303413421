import React, { useEffect, useState } from "react";
import { useUser } from "../context/providers/userContext";
import Chat from "./Chat";
import { useMessages } from "../context/providers/messagesContext";
import { Spinner } from "./ui/Spinner";
import { Link } from "react-router-dom";

const PanelChat = ({socket}) => {
  const { user, getUserIdChat, isLoading, updateMyUser } = useUser();
  const { messagesUserToUser, isLoadingChatHistory } = useMessages();

  const [viewMessages, setViewMessages] = useState([]);

  const handleChat = async (idUser) => {
    await messagesUserToUser(idUser);
    await getUserIdChat(idUser);

    setViewMessages((prevState) => ({
      ...prevState,
      [idUser]: !prevState[idUser],
    }));
  };

  useEffect(() =>{
    const start = async() =>{
      await updateMyUser(user._id);
    }
    start();
  },[]);

  return (
    <section className="section-panel-chat">
      <article className="header-chat-container">
        <div className="img-chat-container-relative">
          <div className="img-panel-chat-container">
            <img src={user?.image?.url} alt="Foto de perfil" />
          </div>
        </div>
        <div className="name-chat-container">
          <h2>{user?.name}</h2>
          <h3>on-line</h3>
        </div>
      </article>
      <article className="panel-chat-content">
        <div className="panel-messages-card">
          {isLoadingChatHistory ? (
            Spinner
          ) : (
            <>
              {user?.chatHistory &&
                user?.chatHistory.map((chat) => (
                  <div key={chat._id}>
                    <div className="chats-container">
                      <div className="chats-img-perfil-container">
                      <Link to={`/profile/${chat?._id}`}>
                        <img src={chat.image?.url ||
                          "https://res.cloudinary.com/dd8a6qc45/image/upload/v1690634531/Volunteers/perfilImage/account_e6qpmh.svg"} alt="Foto de perfil" />
                        </Link>

                      </div>
                      <h4
                        onClick={() => {
                          handleChat(chat._id);
                        }}
                      >
                        {chat.name}
                      </h4>
                    </div>
                    {viewMessages[chat._id] && (
                      <Chat idUser={chat._id} close={setViewMessages} socket={socket}/>
                    )}
                  </div>
                ))}
            </>
          )}
        </div>
      </article>
    </section>
  );
};
export default PanelChat;
