import React from "react";

const OdsActivity12 = () => {
  return (
    <div className="ods-activities-container">
      <h2>ODS ACTIVIDAD 12:</h2>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645230/Volunteers/assets/ODS/7_gguoxk.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 7: Energía asequible y no contaminante: (Meta 7.3) </span>{" "}
          <br />
          Esta práctica reduce el consumo de energía, lo que contribuye a una
          mayor eficiencia energética y disminuye la demanda de energía
          proveniente de fuentes no renovables. Asimismo, promueve el uso
          responsable y eficiente de la energía.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645227/Volunteers/assets/ODS/9_gwz6lw.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>
            ODS 9: Industria, innovación e infraestructura: (Meta 9.4){" "}
          </span>{" "}
          <br />
          Reducir el consumo de energía en los hogares puede incentivar el
          desarrollo y la adopción de tecnologías de energía más eficientes y
          limpias, apoyando la transición hacia una infraestructura más
          sostenible y menos dependiente de combustibles fósiles.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/11_qyelnc.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 11: Ciudades y comunidades sostenibles: (Meta 11.6) </span>{" "}
          <br />
          Al promover el uso eficiente de la energía en los hogares, se
          contribuye a la sostenibilidad de las comunidades urbanas y rurales,
          haciendo que las ciudades y asentamientos humanos sean más sostenibles
          y menos dependientes de la energía no renovable.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/12_totmyp.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>
            ODS 12: Producción y consumo responsables: (Meta 12.2, 12.8){" "}
          </span>{" "}
          <br />
          Apagar las luces y desconectar aparatos promueve hábitos de consumo
          responsable y conciencia sobre el uso eficiente de los recursos
          naturales. Esto fomenta una mayor responsabilidad individual y
          comunitaria en el uso sostenible de la energía.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/13_tmtnkh.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 13: Acción por el clima: (Meta 13.2) </span> <br />
          Reducir el consumo de energía en los hogares contribuye directamente a
          la reducción de las emisiones de gases de efecto invernadero. Este
          tipo de acciones individuales y colectivas son esenciales para mitigar
          el cambio climático y alcanzar las metas de reducción de emisiones
          establecidas en los acuerdos internacionales.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/15_hd4qmx.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 15: Vida de ecosistemas terrestres: (Meta 15.1) </span>{" "}
          <br />
          Menos demanda de energía puede reducir la necesidad de infraestructura
          energética que impacta negativamente a los ecosistemas terrestres,
          como la construcción de represas hidroeléctricas o la explotación de
          combustibles fósiles. Esto contribuye a la conservación y uso
          sostenible de los ecosistemas terrestres.
        </p>
      </div>
    </div>
  );
};

export default OdsActivity12;
