import axios from "axios";

import { apiURL } from "./apiConfig";

export const getTareasRequest = async () =>
  await axios.get(`${apiURL}/api/tareas`);

export const getTareaRequest = async (id) => {
  return await axios.get(`${apiURL}/api/tareas/` + id);
};

export const getTareaRandomRequest = async () => {
  try {
    return await axios.get(`${apiURL}/api/tarea/rand`);
  } catch (error) {
    throw error.response.data;
  }
};

export const getTareasRandomRequest = async (page, limit) =>{
  try {
    return await axios.get(`${apiURL}/api/tareas-random?page=${page}&limit=${limit}`);
  } catch (error) {
    console.log(error.response);
    throw error.response.data;
  }
}

export const postTareasRequest = async (tarea) => {
  const form = new FormData();
  for (let key in tarea) {
    form.append(key, tarea[key]);
  }
  return await axios.post(`${apiURL}/api/tareas`, form, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const deleteTareaRequest = async (id) =>
  await axios.delete(`${apiURL}/api/tareas/` + id);

export const updateTareaRequest = async (id, newFields) => {
  const token = localStorage.getItem("token");
  try {
    const form = new FormData();
    for (const key in newFields) {
      form.append(key, newFields[key]);
    }
    const formDataObject = {};
    for (const [key, value] of form.entries()) {
      formDataObject[key] = value;
    }

    return await axios.put(`${apiURL}/api/tareas/${id}`, form, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: token,
      },
    });
  } catch (error) {
    console.log(error);
  }
};
