import React from "react";

const OdsActivity4 = () => {
  return (
    <div className="ods-activities-container">
      <h2>ODS ACTIVIDAD 4:</h2>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645231/Volunteers/assets/ODS/4_xqio0d.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 4: Educación de Calidad: (Meta 4.7) </span> <br />
          Crear artículos con materiales reciclados puede ser una excelente
          herramienta educativa para enseñar a estudiantes sobre la importancia
          del reciclaje, la sostenibilidad y la economía circular. Además,
          fomenta la creatividad y el aprendizaje práctico sobre la
          reutilización de recursos.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645227/Volunteers/assets/ODS/8_sxwmwv.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>
            ODS 8: Trabajo Decente y Crecimiento Económico (Meta 8.4){" "}
          </span>{" "}
          <br />
          La creación de artículos con materiales reciclados promueve un uso más
          eficiente de los recursos y puede generar oportunidades de empleo en
          el sector del reciclaje y la manufactura sostenible. Esto apoya el
          crecimiento económico sostenible sin comprometer el medio ambiente.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645227/Volunteers/assets/ODS/9_gwz6lw.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>
            ODS 9: Industria, Innovación e Infraestructura: (Meta 9.4){" "}
          </span>{" "}
          <br />
          La construcción de artículos con materiales reciclados impulsa la
          innovación y el desarrollo de tecnologías sostenibles, promoviendo
          industrias que se basan en el reciclaje y la reutilización de
          recursos.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/11_qyelnc.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 11: Ciudades y Comunidades Sostenibles: (Meta 11.6) </span>{" "}
          <br />
          Fomentar el uso de materiales reciclados contribuye a la gestión
          sostenible de los residuos en las comunidades, reduciendo la cantidad
          de desechos enviados a los vertederos y promoviendo la economía
          circular.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/12_totmyp.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 12: Producción y Consumo Responsables: (Meta 12.5) </span>{" "}
          <br />
          Construir artículos con materiales reciclados directamente contribuye
          a la reducción de desechos y promueve el reciclaje y la reutilización,
          pilares fundamentales de un consumo y producción responsables.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/13_tmtnkh.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 13: Acción por el Clima: (Meta 13.3) </span> <br />
          Reciclar materiales reduce la necesidad de producir nuevos materiales,
          lo que a su vez disminuye las emisiones de gases de efecto invernadero
          asociadas con la fabricación y el transporte de esos materiales,
          contribuyendo a la mitigación del cambio climático.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/14_mbd8kd.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 14: Vida Submarina: (Meta 14.1) </span> <br />
          Al reutilizar materiales y reducir la generación de residuos, se
          disminuye la cantidad de desechos que pueden terminar en los océanos,
          contribuyendo a la protección de la vida marina y a la salud de los
          ecosistemas acuáticos.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/15_hd4qmx.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 15: Vida de Ecosistemas Terrestres: (Meta 15.1) </span>{" "}
          <br />
          Utilizar materiales reciclados reduce la presión sobre los recursos
          naturales y ayuda a proteger los ecosistemas terrestres al disminuir
          la necesidad de extraer nuevos materiales del entorno natural.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/17_e8x3cm.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 17: Alianzas para lograr los objetivos: (Meta 17.17) </span>{" "}
          <br />
          Promover la construcción con materiales reciclados puede involucrar a
          diversas partes interesadas, incluyendo gobiernos, empresas y
          organizaciones no gubernamentales, fomentando colaboraciones y
          alianzas para promover prácticas sostenibles y alcanzar los ODS.
        </p>
      </div>
    </div>
  );
};

export default OdsActivity4;
