import React, { useEffect } from "react";
import { useUser } from "../context/providers/userContext";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCameraRetro } from "@fortawesome/free-solid-svg-icons";
import { Spinner } from "../components/ui/Spinner";
import "../styles/configPerfil.css";
import Swal from "sweetalert2";

const ConfigPerfil = () => {
  const { user, isLoading, getUserId, userId, deleteMyAccount, logout } = useUser();

  const handleDelete = async () => {
    try {
      Swal.fire({
        title:
          "Estas seguro de borrar tu cuenta? No la podras volver a recuperar.",
        showDenyButton: true,
        // showCancelButton: true,
        confirmButtonText: "Eliminar",
        denyButtonText: `No eliminar`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const res = await deleteMyAccount(user._id);
          await logout();
          console.log(res);
          Swal.fire("Cuenta eliminada!", "", "success");
        } else if (result.isDenied) {
          Swal.fire("Cuenta salvada!", "", "info");
        }
      });
    } catch (error) {}
  };

  useEffect(() => {
    const start = async () => {
      await getUserId(user._id);
    };
    start();
  }, []);

  return (
    <main className="main-accountfree">
      {isLoading ? (
        Spinner
      ) : (
        <>
          <div className="accountfree-container">
            <section className="head-perfil">
              <div className="img-fondo">
                <img
                  src="https://images.pexels.com/photos/1324803/pexels-photo-1324803.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                  alt="Imagen de fondo"
                />
              </div>
              <div className="img-perfil-card">
                <div className="img-perfil">
                  <img
                    src={
                      user?.image && user?.image?.url
                        ? user?.image?.url
                        : "https://res.cloudinary.com/dd8a6qc45/image/upload/v1690634531/Volunteers/perfilImage/account_e6qpmh.svg"
                    }
                    alt="Foto de perfil"
                  />
                  <h1 className="title-name-user-perfil">{user?.name}</h1>

                  <div className="update-perfil">
                    <Link to={`/update-perfil/${user?._id}`}>
                      <FontAwesomeIcon icon={faCameraRetro} />
                    </Link>
                  </div>
                </div>
              </div>
            </section>
            <section className="config-perfil-section">
              <article className="perfil-dates-article">
                <div>
                  <div className="date-perfil-container">
                    <h2>Nombre:</h2>
                    <hr />
                    <p>{userId?.name}</p>
                  </div>
                  <div className="date-perfil-container">
                    <h2>Documento:</h2>
                    <hr />
                    <p>{userId?.document}</p>
                  </div>
                  <div className="date-perfil-container">
                    <h2>Email:</h2>
                    <hr />
                    <p>{userId?.email}</p>
                  </div>
                </div>
                <div>
                  <div className="date-perfil-container">
                    <h2>Numero:</h2>
                    <hr />
                    <p>{userId?.number}</p>
                  </div>
                  <div className="date-perfil-container">
                    <h2>Ocupacion:</h2>
                    <hr />
                    <p>{userId?.ocupation}</p>
                  </div>
                  <div className="date-perfil-container">
                    <h2>Ciudad:</h2>
                    <hr />
                    <p>{userId?.country}</p>
                  </div>
                </div>
              </article>
              <article className="delete-account-article">
                <div className="note-admin-perfil-container">
                  <h2>Nota de administrador:</h2>
                  <hr />
                  <p>{userId?.adminNote}</p>
                </div>
                <button
                  className="button-delete-account"
                  onClick={handleDelete}
                >
                  Eliminar cuenta permanentemente...
                </button>
              </article>
            </section>
          </div>
        </>
      )}
    </main>
  );
};

export default ConfigPerfil;
