import React from "react";

const OdsActivity2 = () => {
  return (
    <div className="ods-activities-container">
      <h2>ODS ACTIVIDAD 2:</h2>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645224/Volunteers/assets/ODS/1_b9ekwj.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 1: Fin de la pobreza: (Meta 1.5) </span> <br />
          Los árboles pueden proporcionar alimentos, madera y otros recursos que
          pueden ser una fuente de ingresos para las comunidades vulnerables.
          Plantar o adoptar árboles nativos puede ayudar a mejorar la
          resiliencia económica de estas comunidades al diversificar sus fuentes
          de ingresos.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645231/Volunteers/assets/ODS/3_tqqywb.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 3: Salud y bienestar: (Meta 3.9) </span> <br />
          Los árboles mejoran la calidad del aire al absorber dióxido de carbono
          y liberar oxígeno, lo que contribuye a una mejor salud respiratoria y
          general de las personas. También proporcionan sombra y reducen el
          efecto de isla de calor en áreas urbanas, mejorando el bienestar de la
          comunidad.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645230/Volunteers/assets/ODS/6_mwuiqs.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 6: Agua limpia y saneamiento: (Meta 6.6) </span> <br />
          Los árboles juegan un papel crucial en el ciclo del agua, ayudando a
          mantener la humedad del suelo y reducir la escorrentía de agua.
          Plantar o adoptar árboles nativos contribuye a la protección y
          restauración de los ecosistemas relacionados con el agua.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/11_qyelnc.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 11: Ciudades y comunidades sostenibles: (Meta 11.7) </span>{" "}
          <br />
          Los árboles urbanos contribuyen a la sostenibilidad de las ciudades al
          mejorar la calidad del aire, proporcionar sombra y reducir el calor
          urbano. Además, embellecen los espacios públicos y pueden aumentar el
          valor de las propiedades.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/12_totmyp.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>
            ODS 12: Producción y consumo responsables: (Meta 12.2, 12.5){" "}
          </span>{" "}
          <br />
          Utilizar árboles nativos en lugar de especies exóticas promueve la
          biodiversidad local y el uso eficiente de los recursos naturales.
          Además, los árboles pueden ser una fuente sostenible de productos
          naturales, contribuyendo a una producción y consumo más responsables.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/13_tmtnkh.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 13: Acción por el clima: (Meta 13.1, 13.2) </span> <br />
          Plantar árboles es una estrategia efectiva para mitigar el cambio
          climático, ya que los árboles absorben dióxido de carbono, uno de los
          principales gases de efecto invernadero. Los bosques también actúan
          como sumideros de carbono.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/15_hd4qmx.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>
            ODS 15: Vida de ecosistemas terrestres: (Meta 15.1, 15.2){" "}
          </span>{" "}
          <br />
          Plantar árboles nativos ayuda a restaurar y conservar los ecosistemas
          terrestres, promoviendo la biodiversidad y proporcionando hábitat para
          la fauna local. También ayuda a combatir la desertificación y la
          degradación del suelo.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/17_e8x3cm.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 17: Alianzas para lograr los objetivos: (Meta 17.17) </span>{" "}
          <br />
          Plantar y adoptar árboles puede ser una actividad comunitaria que
          fomente la colaboración entre diferentes actores, incluidos gobiernos,
          empresas, organizaciones no gubernamentales y ciudadanos. Estas
          alianzas pueden fortalecer los esfuerzos para alcanzar los objetivos
          de sostenibilidad y protección ambiental.
        </p>
      </div>
    </div>
  );
};

export default OdsActivity2;
